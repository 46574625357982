.table-actions {
    background: $pink1; //padding: 16px 22px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    height: 70px;
    max-height: 70px;
    @include b(1600) {
        height: 62px;
        max-height: 62px;
    }
    @include b(tablet) {
        display: none;
    }
    &__right {
        flex-direction: row;
        display: flex;
        align-items: center;
        align-self: stretch;
    }
    &__text {
        color: $white;
        font: 400 14px $font;
        text-transform: uppercase;
        display: block;
        @include b(1600) {
            font-size: 11px;
        }
        @include b(tablet) {
            display: none;
        }
    }
    &__list {
        display: flex;
        &-item {
            display: inline-block;
            vertical-align: top;
            margin-right: 18px;
            &:last-child {
                margin-right: 0;
            }
        }
        &-link {
            text-transform: uppercase;
            color: $white;
            display: block;
            text-align: center;
            font: 400 13px $font;
            border: 2px solid rgba($white, .2);
            padding: 9px 10px;
            &:hover {
                border-color: $white;
            }
            &:focus {
                color: $pink1;
                border-color: $white;
                background: $white;
            }
        }
        @include b(1600) {
            &-item {
                margin-right: 11px;
            }
            &-link {
                font-size: 11px;
                padding: 7px 5px 6px;
            }
        }
        @include b(tablet) {
            &-item {
                margin-right: 27px;
            }
            &-link {
                font-size: 20px;
                border-width: 3px;
                border-color: $white;
                padding: 13px 15px 14px;
            }
        }
        @include b(mobile) {
            &-item {
                margin-right: 15px;
            }
            &-link {
                font-size: 16px;
                padding: 7px 5px 6px;
                border-width: 2px;
                border-color: $white;
            }
        }
    }
    &__close {
        text-transform: uppercase;
        color: $white;
        fill: $white;
        font: 400 14px $font;
        display: block;
        &-text {
            display: inline-block;
            vertical-align: middle;
        }
        &-ico {
            display: inline-block;
            vertical-align: middle;
            fill: inherit;
            width: 19px;
            height: 19px;
            margin-left: 8px;
            margin-right: 16px;
        }
        &:hover {
            opacity: .8;
        }
        @include b(1600) {
            font-size: 11px;
            &-ico {
                width: 15px;
                height: 15px;
            }
        }
        @include b(tablet) {
            display: none;
        }
    }
}