.table-nav {
    background: $gray3;
    padding: 16px 22px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: space-between;
    align-self: stretch;
    @include b(tablet) {
        display: none;
    }
    &__left {}
    &__right {}
    &__item {
        display: inline-block;
        margin-right: 35px;
        &-text {
            color: $white;
            font: 400 14px $font;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .minimize-select {
        display: inline-block;
        vertical-align: middle;
        &__select {
            background: $gray3;
            color: $white;
            text-align: center;
            text-align-last: center;
        }
        &__arrow {
            z-index: 1;
        }
    }
    &-pagination {
        &__val {
            color: $white;
            font: 400 14px $font;
            display: inline-block;
            vertical-align: middle;
            padding-right: 7px;
        }
        &__box {
            display: inline-block;
            vertical-align: top;
        }
        &__link {
            display: inline-block;
            vertical-align: middle;
            padding: 5px;
            fill: $white;
            &--prev {
                transform: rotate(90deg);
            }
            &--next {
                transform: rotate(-90deg);
            }
            &-ico {
                display: block;
                width: 12px;
                height: 6px;
                fill: inherit;
            }
            &:hover {
                opacity: .8;
            }
        }
    }
}