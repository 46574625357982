.volma-file {
    position: relative;
    display: block;
    padding-bottom: $errorHeight;
    &--flex{
        display: flex;
    }
    &__input {
        cursor: pointer;
        &.disabled {
            cursor: default;
            background: $blue1;
            opacity: 0.18;
        }
        &--clear {
            background: $blue1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:before {
                @include before-img(calc(100% - 16px), calc(100% - 16px));
                top: 8px;
                left: 8px;
                border: 2px dashed rgba($white, .3);
            }
            &:hover:not(.disabled) {
                opacity: .8;
            }
            &-no-border {
                &:before {
                    display: none;
                }
            }
        }
        &--ready {
            display: flex;
            align-items: center;
        }
        &--upload {
            background: $blue1;
            display: flex;
            align-items: center;
            position: relative;
            &:before {
                @include before-img(100%, 100%);
                background: url('../../../img/upload-bg.png') repeat-x;
                background-size: contain;
                top: 0;
                left: 0;
                opacity: .08;
            }
        }
    }
    &__clear {
        &-ico {
            display: inline-block;
            vertical-align: middle;
            fill: $white;
            width: 24px;
            height: 23px;
            margin-right: 5px;
        }
        &-text {
            display: inline-block;
            vertical-align: middle;
            color: $white;
            font: 500 15px $font;
            text-transform: uppercase;
        }
    }
    &__ready {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &-left {}
        &-right {
            // width: 60%;
            flex: 1;
            padding-left: 10px;
            text-align: right;
            display: flex;
        }
        &-name {}
        &-val {
            text-transform: none;
            font-weight: 400;
            text-decoration: underline;
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 40px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &-ico {
                display: inline-block;
                vertical-align: middle;
                fill: $blue1;
            }
        }
        &-ico {
            display: inline-block;
            vertical-align: middle;
            fill: $blue1;
            width: 24px;
            height: 23px;
            margin-left: 9px;
            &--flip {
                transform: rotate(180deg);
            }
        }
        &--pretense {
            .default-file__ready-left {
                width: calc(100% - 50px);
                padding-right: 10px;
                overflow: hidden;
            }
            .default-file__ready-right {
                width: 50px;
            }
            .default-file__ready-name {
                display: inline-block;
                vertical-align: middle;
            }
            .default-file__ready-val {
                display: inline-block;
                vertical-align: middle;
                width: auto;
            }
        }
    }
    &__hide {
        display: none;
    }
    &__upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &-right {
            width: 80px;
        }
        &-left {
            width: calc(100% - 80px);
            padding-right: 10px;
            overflow: hidden;
        }
        &-name {
            color: $white;
            font: 500 15px $font;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
        }
        &-val {
            text-transform: none;
            display: inline-block;
            vertical-align: middle;
            color: $white;
            font: 500 15px $font;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 10px;
            &-text {
                font-weight: 300;
                text-decoration: underline;
            }
        }
        &-progress {
            color: $white;
            font: 700 30px $font;
            display: block;
        }
    }
    &__edit {
        flex: 0 0 65px;
        @include b(1600) {
            flex: 0 0 52px;
        }
        @include b(tablet) {
            flex: 0 0 85px;
        }
        @include b(mobile) {
            flex: 0 0 42px;
        }
    }
    @include b(tablet) {
        &__clear {
            &-text {
                font-size: 20px;
            }
        }
        &__upload {
            &-name {
                font-size: 20px;
            }
            &-val {
                font-size: 20px;
            }
        }
    }
    @include b(mobile) {
        &__input {
            &--clear {
                &:before {
                    width: calc(100% - 8px);
                    height: calc(100% - 8px);
                    top: 4px;
                    left: 4px;
                }
            }
        }
        &__clear {
            &-text {
                font-size: 12px;
            }
            &-ico {
                width: 19px;
                height: 18px;
            }
        }
        &__upload {
            &-name {
                font-size: 12px;
            }
            &-val {
                font-size: 12px;
            }
        }
        &__ready {
            &-ico {
                width: 19px;
                height: 18px;
            }
        }
    }
    @include b(580) {
        &__upload {
            &-val {
                display: block;
            }
        }
    }
}

.default-input-error {
    top: 100%;
    left: 0;
    margin-top: -27px;
    &__text {
        display: inline-block;
        color: #fff;
        font: 400 15px "PFDinTextPro";
        background: #e11248;
        margin: 0 10px 5px 0;
        padding: 2px 6px;
    }
}