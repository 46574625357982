/* Start correspondence block */

.correspondence {
    &__box {
        max-height: 795px;
    }
    &__wrap {
        overflow: auto;
        margin-bottom: 38px;
        border: 2px solid $blue1;
        background: $white;
        padding: 53px 20px 0;
    }
    &__head {
        text-align: center;
        padding-bottom: 39px;
        &-text {
            color: $gray1;
            font: 300 15px $font;
            display: block;
            fill: $blue1;
            text-transform: uppercase;
        }
        &-ico {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 7px;
            fill: inherit;
        }
        &-link {
            color: $blue1;
            font-weight: 700;
            border-bottom: 2px solid $blue1;
            display: inline-block;
            line-height: 14px;
            letter-spacing: .5px;
            &:hover {
                border-color: transparent;
            }
        }
    }
    &__title {
        color: $gray1;
        font: 400 28px $font;
        display: block;
        text-transform: uppercase;
        position: relative;
        margin-bottom: 43px;
        &:before {
            @include before-img(100%, 1px);
            top: calc(50% - 1px);
            left: 0;
            background: $gray1;
        }
        &-text {
            display: table;
            margin: 0 auto;
            position: relative;
            background: $white;
            padding: 0 20px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }
    &__item {
        display: flex;
        padding-bottom: 16px;
        align-self: flex-start;
        &--right {
            align-self: flex-end;
            flex-direction: row-reverse;
            .correspondence__item-right {
                margin-left: 0px;
                margin-right: 20px;
                @include b(580) {
                    margin-right: 0;
                }
            }
            .correspondence-message {
                flex-direction: row-reverse;
                &:before {
                    left: auto;
                    right: -16px;
                    border-color: transparent;
                    border-left-color: inherit;
                }
                @include b(mobile) {
                    flex-direction: column;
                }
                @include b(580) {
                    &:before {
                        border-color: transparent;
                        border-bottom-color: inherit;
                        right: auto;
                        left: calc(50% - 8px);
                    }
                }
            }
        }
        &--blue {
            .correspondence-message {
                transition: all $speed*20 ease-in-out;
                border-color: $blue1;
            }
            .correspondence-user {
                transition: all $speed*20 ease-in-out;
                background: $blue1;
            }
            .correspondence-user__ico-bg {
                transition: all $speed*10 ease-in-out;
                fill: rgba($white, .1);
            }
            .correspondence-message__cont {
                transition: all $speed*20 ease-in-out;
                background: $blue1;
            }
            .correspondence-message__info {
                transition: all $speed*20 ease-in-out;
                background: $gray4;
            }
            .correspondence-message__info-ico {
                transition: all $speed*20 ease-in-out;
                fill: $blue1;
            }
            .correspondence-message__info-text {
                transition: all $speed*20 ease-in-out;
                color: $gray1;
            }
            .correspondence-message__title {
                transition: all $speed*20 ease-in-out;
                color: $white;
                fill: $white;
            }
            .correspondence-message__main-text {
                transition: all $speed*20 ease-in-out;
                color: $white;
            }
        }
        &--gray {
            .correspondence-message {
                transition: all $speed*20 ease-in-out;
                border-color: $gray4;
            }
            .correspondence-user {
                transition: all $speed*20 ease-in-out;
                background: $gray4;
                fill: $blue1;
            }
            .correspondence-user__ico-bg {
                transition: all $speed*20 ease-in-out;
                fill: rgba($gray1, .1);
            }
            .correspondence-message__cont {
                transition: all $speed*20 ease-in-out;
                background: $gray4;
            }
            .correspondence-message__info {
                transition: all $speed*20 ease-in-out;
                background: $blue1;
            }
            .correspondence-message__info-ico {
                transition: all $speed*20 ease-in-out;
                fill: $white;
            }
            .correspondence-message__info-text {
                transition: all $speed*20 ease-in-out;
                color: $white;
            }
            .correspondence-message__title {
                transition: all $speed*20 ease-in-out;
                color: $gray1;
                fill: $gray1;
            }
            .correspondence-message__main-text {
                transition: all $speed*20 ease-in-out;
                color: $gray1;
            }
        }
        &--aqua {
            .correspondence-message {
                transition: all $speed*20 ease-in-out;
                border-color: $blue2;
            }
            .correspondence-user {
                transition: all $speed*20 ease-in-out;
                background: $blue2;
            }
            .correspondence-user__ico-bg {
                transition: all $speed*20 ease-in-out;
                fill: rgba($white, .1);
            }
            .correspondence-message__cont {
                transition: all $speed*20 ease-in-out;
                background: $blue2;
            }
            .correspondence-message__info {
                transition: all $speed*20 ease-in-out;
                background: $blue1;
            }
            .correspondence-message__info-ico {
                transition: all $speed*20 ease-in-out;
                fill: $white;
            }
            .correspondence-message__info-text {
                transition: all $speed*20 ease-in-out;
                color: $white;
            }
            .correspondence-message__title {
                transition: all $speed*20 ease-in-out;
                color: $white;
                fill: $white;
            }
            .correspondence-message__main-text {
                transition: all $speed*20 ease-in-out;
                color: $white;
            }
        }
        &--error {
            transition: all $speed*20 ease-in-out;
            .correspondence-message__info {
                background: $red1;
                &-ico {
                    fill: $white;
                }
                &-text {
                    color: $white;
                }
            }
        }
        &-left {
            width: 63px;
        }
        &-right {
            width: calc(100% - 63px);
            max-width: 946px;
            margin-left: 20px;
        }
        &-date {
            color: rgba($gray1, .6);
            font: 500 13px $font;
            display: block;
            text-align: right;
            padding-top: 5px;
        }
        @include b(580) {
            flex-direction: column;
            align-items: center;
            &-left {}
            &-right {
                width: 100%;
                margin: 20px 0 0;
            }
        }
    }
    &-user {
        position: relative;
        overflow: hidden;
        background: $blue1;
        fill: $white;
        width: 63px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__ico {
            display: block;
            fill: inherit;
            &-bg {
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                fill: rgba($white, .1);
            }
        }
    }
    &-message {
        display: flex;
        position: relative;
        &:before {
            @include before-img(0, 0);
            border: 8px solid transparent;
            border-right-color: inherit;
            top: 26px;
            left: -16px;
        }
        @include b(mobile) {
            flex-direction: column;
        }
        @include b(580) {
            &:before {
                border-color: transparent;
                border-bottom-color: inherit;
                top: -16px;
                left: calc(50% - 8px);
            }
        }
        &__top {
            padding-bottom: 15px;
        }
        &__title {
            color: $white;
            font: 300 15px $font;
            display: block;
            fill: $white;
            text-transform: uppercase;
            &-ico {
                display: inline-block;
                vertical-align: middle;
                fill: inherit;
                margin-right: 6px;
            }
            &-text {
                display: inline;
                vertical-align: middle;
            }
        }
        &__cont {
            background: $blue1;
            padding: 20px 20px 24px;
            width: calc(100% - 115px);
            @include b(mobile) {
                width: 100%;
            }
        }
        &__main {
            &-text {
                color: $white;
                font: 400 15px $font;
                display: block;
                padding: 0;
            }
        }
        &__info {
            width: 115px;
            background: $gray4;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include b(mobile) {
                width: 100%;
            }
            &-ico {
                display: block;
                fill: $blue1;
                margin-bottom: 10px;
            }
            &-text {
                color: $gray1;
                font: 700 13px $font;
                display: block;
                text-transform: uppercase;
                text-align: center;
            }
            &-error {
                color: $white;
                font: 400 12px $font;
                display: block;
                text-transform: uppercase;
                padding-top: 5px;
                text-align: center;
            }
        }
    }
}


/* Finish correspondence block */