
.volma-checkbox {
  &__container {
    display: flex;
    align-items: center;
    min-height: 65px;
    margin-bottom: 34px;
    padding: 10px 18px;
    border: 2px solid $blue1;

    @include b(mobile) {
      margin-bottom: 0;
    }
  }
  
  &__label {
    
    text-transform: uppercase;
    display: block;
    font: 500 15px $font;

    flex-shrink: 0;
    padding: .2rem .5rem;
    color: $gray1;
    cursor: pointer;
    user-select: none;

    @include b(1600) {
      font-size: 13px;
    }
    @include b(tablet) {
        font-size: 20px;
    }
    @include b(mobile) {
        font-size: 12px;
    }
  }

  &__input {
    position: relative;
    flex-shrink: 0;
    width: 23px;
    height: 23px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    border: 2px solid $blue1;
    margin-top: 10px;
    margin-bottom: 10px;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all .1s;
      background: $blue1;
    }

    &:checked {
      &::before {
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;

        @include b(tablet) {
          top: 4px;
          right: 4px;
          bottom: 4px;
          left: 4px;
        }
      }
    }
    @include b(tablet) {
      width: 40px;
      height: 40px;
    }
  }
}
