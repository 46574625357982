.more-file {
    display: flex;
    @include b(mobile) {
        flex-wrap: wrap;
    }
    &__left {
        border: 2px solid $blue1;
        border-right: 0;
        flex-basis: 27%;
        padding: 23px 20px;
        @include b(tablet) {
            flex-basis: 26%;
        }
        @include b(mobile) {
            flex-basis: 80%;
            order: 0;
        }
    }
    &__center {
        border: 2px solid $blue1;
        border-right: 0;
        flex-basis: 66%;
        @include b(tablet) {
            flex-basis: 64%;
        }
        @include b(mobile) {
            flex-basis: 100%;
            order: 2;
            margin-top: -2px;
            border: 2px solid $blue1;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $blue1;
        flex-basis: 7%;
        @include b(tablet) {
            flex-basis: 10%;
        }
        @include b(mobile) {
            flex-basis: 20%;
            order: 1;
        }
    }
    &__title {
        color: $gray1;
        font: 500 15px $font;
        display: block;
        text-transform: uppercase;
        @include b(tablet) {
            font-size: 20px;
        }
        @include b(mobile) {
            font-size: 13px;
        }
    }
    &__row {
        fill: $blue1;
        display: flex;
        padding: 5px 2px;
        margin: 0 -2px;
        min-height: 62px;
        align-items: center;
        color: $gray1;
        border-bottom: 2px solid transparent;
        position: relative;
        &--blue {
            background: $blue1;
            fill: $white;
            color: $white;
        }
        &--border {
            border-color: $light;
        }
        &-left {
            width: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            &-ico {
                display: block;
                fill: inherit;
            }
        }
        &-center {
            width: calc(100% - 110px);
            padding: 0 5px;
        }
        &-right {
            width: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            &-ico {
                fill: inherit;
                display: block;
            }
        }
    }
    &__name {
        color: inherit;
        font: 500 15px/1 $font;
        display: block;
        text-decoration: underline;
        margin-bottom: 1px;
        @include b(tablet) {
            font-size: 20px;
        }
        @include b(mobile) {
            font-size: 13px;
        }
    }
    &__info {
        display: block;
        color: inherit;
        font: 400 13px/1 $font;
        @include b(tablet) {
            font-size: 15px;
        }
        @include b(mobile) {
            font-size: 12px;
        }
    }
    &__add {
        fill: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background: $green1;
        &-ico {
            width: 20px;
            height: 20px;
            display: block;
            fill: inherit;
        }
    }
}