.input-dark {
    position: relative;
    &__input {
        border: 2px solid $white;
        color: $white;
        font: 400 25px $font;
        text-transform: none;
        height: 50px;
        width: 100%;
        background: none;
        padding: 0 60px 0 18px;
        position: relative;
        z-index: 1;
    }
    &__icon {
        position: absolute;
        right: 0;
        top: 0;
        fill: $white;
        display: flex;
        border-left: 2px solid $white;
        height: 100%;
        width: 50px;
    }
    &__ico {
        display: block;
        fill: inherit;
        margin: auto;
    }
    @include b(1600) {
        &__input {
            font-size: 16px;
            height: 35px;
        }
    }
    @include b(tablet) {
        &__input {
            font-size: 25px;
            height: 50px;
        }
    }
    @include b(mobile) {
        &__input {
            font-size: 16px;
            height: 35px;
        }
    }
}