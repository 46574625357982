.footer {
    background: $pink1;
    padding: 20px 0;
    @extend %reset-block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transform: translate3d(0, 0, 0);
    transition: all $speed;
    visibility: hidden;
    transform: translateY(100%);

    &.active {
        transition: all $speed;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    @include b(tablet) {
        // position: relative;
        overflow: auto;
        padding: 23px 0;
    }
    @include b(mobile) {
        padding: 16px 0;
    }
    &__box {
        @include b(tablet) {
            width: 1281px;
        }
        @include b(mobile) {
            width: 916px;
        }
    }
    &__flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__text {
        color: $white;
        font: 400 14px $font;
        text-transform: uppercase;
        display: block;
        @include b(1600) {
            font-size: 11px;
        }
        @include b(tablet) {
            display: none;
        }
    }
    &-nav {
        &__list {
            display: flex;
            &-item {
                display: inline-block;
                vertical-align: top;
                margin-right: 18px;
                &:last-child {
                    margin-right: 0;
                }
            }
            &-link {
                text-transform: uppercase;
                color: $white;
                display: block;
                text-align: center;
                font: 400 13px $font;
                border: 2px solid rgba($white, .2);
                padding: 9px 10px;
                &:hover {
                    border-color: $white;
                }
                &:focus {
                    color: $pink1;
                    border-color: $white;
                    background: $white;
                }
            }
            @include b(1600) {
                &-item {
                    margin-right: 11px;
                }
                &-link {
                    font-size: 11px;
                    padding: 7px 5px 6px;
                }
            }
            @include b(tablet) {
                &-item {
                    margin-right: 27px;
                }
                &-link {
                    font-size: 20px;
                    border-width: 3px;
                    border-color: $white;
                    padding: 13px 15px 14px;
                }
            }
            @include b(mobile) {
                &-item {
                    margin-right: 15px;
                }
                &-link {
                    font-size: 16px;
                    padding: 7px 5px 6px;
                    border-width: 2px;
                    border-color: $white;
                }
            }
        }
    }
    &__close {
        text-transform: uppercase;
        color: $white;
        fill: $white;
        font: 400 14px $font;
        display: flex;
        align-items: center;
        &-text {
            vertical-align: middle;
        }
        &-ico {
            vertical-align: middle;
            fill: inherit;
            width: 19px;
            height: 19px;
            margin-left: 8px;
        }
        &:hover {
            opacity: .8;
        }
        @include b(1600) {
            font-size: 11px;
            &-ico {
                width: 15px;
                height: 15px;
            }
        }
        @include b(tablet) {
            display: none;
        }
    }
}