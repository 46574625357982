.input-border {
    &__input {
        border: 0;
        border-bottom: 1px solid $white;
        height: auto;
        min-height: initial;
        padding: 0 0 4px 0;
        color: $white;
        font: 200 25px $font;
        text-transform: uppercase;
        &--no-uppercase {
            text-transform: none;
        }
    }
    &__desc {
        color: rgba($white, .4);
        font: 300 15px $font;
        display: block;
        padding-top: 2px;
    }
    &--error {
        .input-border__input {
            text-transform: none;
            font-size: 18px;
        }
        .input-border__desc {
            color: $red2;
            font-weight: 400;
        }
    }
    &--mistake {
        .input-border__input {
            border-color: $red2;
        }
        .input-border__desc {
            color: $red2;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
    @include b(1600) {
        &__input {
            font-size: 23px;
            height: auto;
        }
        &__desc {
            font-size: 13px;
            padding-top: 4px;
        }
    }
    @include b(tablet) {
        &__input {
            font-size: 31px;
            height: auto;
        }
        &__desc {
            font-size: 19px;
            padding-top: 6px;
        }
    }
    @include b(mobile) {
        &__input {
            font-size: 15px;
            height: auto;
        }
        &__desc {
            font-size: 12px;
            padding-top: 3px;
        }
    }
}

.title-drop-parent {
    .input-border {
        &__input {
            color: $gray1;
        }
        &__desc {
            color: $gray1;               
        }
    }
}