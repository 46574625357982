.main-info {
    @extend %reset-block;
    &--report {
        position: relative;
        &:before {
            @include before-img(100%, calc(100% + 150px));
            top: -150px;
            left: 0;
            background: url('../../../img/infoblock_form_img.png') center no-repeat;
            background-size: cover;
            opacity: 0.2;
            max-height: 1000px;
        }
    }
    &__item {
        padding: 43px 0 43px;
        background: $gray4;
        border-bottom: 1px solid $gray5;
        border-top: 1px solid $gray4;
        &:first-child {
            // border-top: 0;
        }
        &:last-child {
            // border-bottom: 0;
        } // &:nth-child(2n) {
        //     background: $light;
        // }
        &--light {
            background: $light;
        }
        &--dark {
            background: $gray4;
        }
        &--blue {
            background: $blue1;
            .main-info__title {
                color: $white;
            }
            .select,
            .input {
                background: $white;
            }
        }
        &--title {
            padding-top: 24px;
            padding-bottom: 35px;
        }
        @include b(1600) {
            padding: 28px 0 28px;
            &:first-child {
                padding-bottom: 29px;
            }
        }
        @include b(tablet) {
            padding: 40px 0 44px;
            &:first-child {
                padding-top: 38px;
                padding-bottom: 44px;
            }
            &:last-child {
                padding-bottom: 30px;
            }
        }
        @include b(mobile) {
            padding: 18px 0 20px;
            &:first-child {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
    &__top {
        display: flex;
        align-items: center;
        padding-bottom: 28px;
        @include b(1600) {
            padding-bottom: 22px;
        }
        @include b(mobile) {
            padding-bottom: 15px;
        }
    }
    &__title {
        padding: 0;
        padding-right: 28px;
        @include b(tablet) {
            // width: 50%;
        }
    }
    &__row {
        margin: 0 -21px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: -$errorHeight;
        &--report {
            margin: 0 -21px 0px 0;
        }
        &--extra-info {
            display: flex;
            margin-bottom: -2px;
            @include b(mobile) {
                margin-bottom: 0px;
            }
        }
        &--indent {
            margin-bottom: 28px;
        }
        &--top-margin {
            margin-top: $errorHeight;
            min-height: 65px;
        }
        @include b(mobile) {
            margin: 0 -15px;
            &--indent {
                margin-bottom: 10px;
            }
            &--report {
                margin: 0 -15px 0px 0;
            }
        }
    }
    &__cell {
        width: 50%;
        display: flex;
        vertical-align: top;
        padding: 0 21px 0 21px;
        &--full {
            padding: 0 21px;
        }
        &--100 {
            width: 100%;
        }
        &--33 {
            width: 33.33%;
        }
        &--25 {
            width: 25%;
        }
        &--wrap {
            flex-wrap: wrap;
        }
        &--padding {
            padding-bottom: $errorHeight;
        }
        &--indent {}
        &--report {
            padding: 0 21px 21px 0;
        }
        @include b(1600) {
            width: 100%;
            padding-bottom: 15px;
            &--33 {
                width: 33.33%;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        @include b(tablet) {
            padding-bottom: 20px;
            &--33 {
                width: 100%;
            }
            &--full {
                padding-bottom: 0;
            }
            &--indent {
                margin-bottom: 18px;
            }
        }
        @include b(mobile) {
            flex-wrap: wrap;
            padding: 0 15px;
            padding-bottom: 10px;
            &--full {
                padding-bottom: 0;
            }
            &--indent {
                margin-bottom: 8px;
            }
            &--report {
                padding: 0 15px 15px 0;
            }
        }
    }
    &__line {
        display: inline-block;
        vertical-align: top;
        padding-right: 21px; // .volma-select__select {
        //     border-right-width: 0;
        // }
        // .input {
        //     border-right-width: 0;
        // }
        &:last-child:not(.flex-wrap):not(.main-info__line--report)
        {
            padding-right: 0; //     .volma-select__select {
            //         border-right-width: 2px;
            //     }
            //     .input {
            //         border-right-width: 2px;
            //     }
        }
        &:last-child {
            .default-select__select {
                border-right-width: 2px;
            }
            .input {
                border-right-width: 2px;
            }
        }
        &--flex50 {
            flex: 0 0 50%;
        }
        &--no-right-padding {
            padding-right: 0;
        }
        &.disabled {
            transition: all $speed;
            opacity: 0.18;
        }
        &--flex {
            display: flex;
        }
        &--time {
            flex: 0 0 260px
        }
        &--date {
            flex: 0 0 220px
        }
        &--fill {
            flex: 1 1 100%;
            max-width: 100%;
        }
        &--large {
            width: calc(60% - 130px);
        }
        &--medium {
            width: calc(40% - 130px);
        }
        &--small {
            width: 100px;
        }
        &--mini {
            width: 80px;
        }
        &--full {
            width: 100%;
            margin: 0;
        }
        &--number-text {
            width: 60%;
        }
        &--number-input {
            width: 20%;
        }
        &--date-number {
            width: calc(100% - 260px);
        }
        &--indent {
            margin-bottom: 18px;
        }
        &--no-error-space {
            margin-bottom: -$errorHeight - 2px;
        }
        &--report {
            background: $white;
            padding: 16px;
        }
        @include b(tablet) {
            &--large {
                width: 100%;
                margin-bottom: -2px;
                .default-select__select {
                    border-right-width: 2px;
                }
                .input {
                    border-right-width: 2px;
                }
            }
            &--medium {
                width: 40%;
            }
            &--small {
                width: 24%;
            }
            &--mini {
                width: 18%;
            }
            &--number-text {
                width: 50%;
            }
            &--number-input {
                width: 25%;
            }
            &--full {
                margin-bottom: -2px;
            }
            &--date-number {
                width: 100%;
                margin-bottom: -2px;
                .input {
                    border-width: 2px;
                }
                &+.main-info__line--small {
                    width: 50%;
                    &+.main-info__line--mini {
                        width: 25%;
                        &+.main-info__line--mini {
                            width: 25%;
                        }
                    }
                }
            }
        }
        @include b(mobile) {
            &--medium {
                width: 100%;
                .default-select__select {
                    border-right-width: 2px;
                }
                .input {
                    border-right-width: 2px;
                }
            }
            &--small {
                width: 50%;
            }
            &--mini {
                width: 25%;
            }
            &--flex50 {
                flex: 0 0 100%;
            }
        }
    }
    &__box {
        display: inline-block;
        vertical-align: top;
        &--left {
            width: calc(60% - 130px);
        }
        &--right {
            width: calc(40% + 130px);
            padding-left: 42px;
        }
        @include b(tablet) {
            &--left {
                width: 40%;
                .input {
                    border-right-width: 0;
                }
            }
            &--right {
                width: 60%;
                padding: 0;
            }
        }
        @include b(mobile) {
            &--left {
                width: 100%;
                .input {
                    border-right-width: 2px;
                }
            }
            &--right {
                width: 100%;
            }
        }
    }
    &-specification {
        padding-bottom: $errorHeight;
        &__box {
            padding-bottom: 18px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        &__text {
            color: $gray1;
            font: 500 15px $font;
            display: inline-block;
            text-transform: uppercase;
            padding-right: 5px;
        }
        &__val {
            @extend .main-info-specification__text;
            text-transform: none;
            font-weight: 300;
            fill: $blue1;
            .wheel,
            .size-vertical,
            .size-horizontal,
            .rub {
                width: 12px;
                height: 13px;
                margin: 0 8px 0 3px;
                display: inline-block;
            }
        }
    }
    &-description {
        // padding-bottom: $errorHeight;
        &__text {
            font: 300 15px $font;
            display: block;
            padding: 0;
            color: $gray1;
        }
        &__title {
            color: $gray1;
            font: 500 15px $font;
            text-transform: uppercase;
        }
    }

    &__warn {
        min-height: 20px;

        &--text {
            background: #ffa600;
            border: 10px solid #7a8595;
            padding: 10px;

            p, h3 {                
                font: 500 17px "PFDinTextPro";
                color: #fff;     
                padding: 2px;           
            }
            
        }
    }
}