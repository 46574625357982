
/* Start rates winner block */
.rates-winner {
	background: $blue1 url('../../../img/rates-winner-bg.png');
	background-size: cover;
	padding: 60px 0 53px;
	@extend %reset-block;

	&__title {
		color: $white;
		padding-bottom: 4px;
	}

	&__flex {
		max-width: 1070px;
		margin: 0 auto;
	}

	&__item {
		background: $blue2;
		padding: 20px;
		position: relative;
		margin: 0 auto 37px;
		display: flex;
		&:last-child {
			margin-bottom: 0;
		}
		&--first {
			max-width: 350px;
			text-align: center;
			flex-direction: column;
			width: 80%;
			.rates-winner__prize {
				padding-bottom: 12px;
			}
			.rates-winner__company {
				padding-bottom: 9px;
			}
		}
		&--second {
			max-width: 655px;
			align-items: center;
			justify-content: space-between;
	    padding: 16px 38px;
	    min-height: 100px;
	    width: 90%;
			.rates-winner__prize {
				font-size: 45px;
			}
			.rates-winner__company {
				&-title {
					padding-bottom: 5px;
				}
				&-name {
					font-size: 18px;
					letter-spacing: 1px;
				}
			}
			.rates-winner__stake {
				&-val {
					font-size: 19px;
					letter-spacing: .5px;
				}
			}
			@include b(tablet) {
				flex-wrap: wrap;
				.rates-winner__prize {
					width: 100%;
				}
			}
			@include b(mobile) {
				.rates-winner__prize {
					font-size: 26px;
					padding-bottom: 10px;
				}
				.rates-winner__company {
					width: 100%;
					padding-bottom: 9px;
					text-align: center;
					&-title {
						font-size: 12px;
						padding-bottom: 0px;
					}
					&-name {
						font-size: 15px;
						letter-spacing: 0px;
					}
				}
				.rates-winner__stake {
					width: 100%;
					text-align: center;
					&-title {
						font-size: 12px;
					}
					&-val {
						font-size: 15px;
						letter-spacing: 0px;
					}
				}
			}
		}
		&--third {
			max-width: 100%;
			align-items: center;
			justify-content: space-between;
	    padding: 16px 38px;
	    min-height: 80px;
			.rates-winner__prize {
				font-size: 35px;
			}
			.rates-winner__company {
				&-title {
					padding-bottom: 0px;
					display: inline-block;
					font-size: 18px;
					margin-right: 5px;
					letter-spacing: 0;
				}
				&-name {
					font-size: 18px;
					letter-spacing: 0px;
					display: inline-block;
				}
			}
			.rates-winner__stake {
				&-title {
					padding: 0;
					display: inline-block;
					font-size: 18px;
					margin-right: 5px;
					letter-spacing: 0;
				}
				&-val {
					display: inline-block;
					font-size: 18px;
					letter-spacing: 0;
				}
			}
			@include b(tablet) {
				flex-wrap: wrap;
				justify-content: space-around;
				.rates-winner__prize {
					width: 100%;
					padding-bottom: 10px;
				}
				.rates-winner__company {
					padding-bottom: 5px;
					&-title {
						display: block;
						font-size: 14px;
					}
				}
				.rates-winner__user {
					padding-bottom: 5px;
					&-title {
						display: block;
						font-size: 14px;
					}
				}
				.rates-winner__stake {
					padding-bottom: 5px;
					&-title {
						display: block;
						font-size: 14px;
					}
				}
			}

			@include b(mobile) {
				justify-content: space-between;
				.rates-winner__prize {
					font-size: 22px;
				}
				.rates-winner__company {
					padding-bottom: 9px;
					width: 100%;
					text-align: center;
					&-title {
						font-size: 12px;
					}
					&-name {
						font-size: 15px;
						letter-spacing: 0px;
					}
				}
				.rates-winner__user {
					padding-bottom: 9px;
					width: 100%;
					text-align: center;
				}
				.rates-winner__stake {
					width: 100%;
					text-align: center;
					padding: 0;
					&-title {
						font-size: 12px;
					}
					&-val {
						font-size: 15px;
						letter-spacing: 0px;
					}
				}
			}

		}
	}

	&__prize {
		color: $white;
		font: 700 61px/1 $font;
		display: block;
		text-align: center;
		letter-spacing: -1px;
		@include b(mobile) {
			font-size: 32px;
			letter-spacing: 0;
		}
	}

	&__company {
		&-title {
			color: $white;
			font: 300 14px/1 $font;
			display: block;
			padding-bottom: 3px;
			letter-spacing: .5px;
		}
		&-name {
			color: $white;
			font: 700 20px/1 $font;
			display: block;
			letter-spacing: 1.5px;
		}
		@include b(mobile) {
			&-title {
				font-size: 12px;
				letter-spacing: 0;
			}
			&-name {
				font-size: 15px;
				letter-spacing: 0;
			}
		}
	}

	&__stake {
		&-title {
			color: $white;
			font: 300 14px $font;
			display: block;
			letter-spacing: .5px;
		}
		&-val {
			color: $white;
			font: 400 21px $font;
			display: block;
			letter-spacing: 1px;
		}
		@include b(mobile) {
			&-title {
				font-size: 12px;
				letter-spacing: 0;
			}
			&-val {
				font-size: 15px;
				letter-spacing: 0;
			}
		}
	}

	&__user {
		&-title {
			color: $white;
			font: 300 18px $font;
			display: inline-block;
			margin-right: 5px;
		}
		&-name {
			color: $white;
			font: 400 18px $font;
			display: inline-block;
		}
		@include b(mobile) {
			&-title {
				font-size: 12px;
			}
			&-name {
				font-size: 15px;
			}
		}
	}

	&__pay {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	&--info {
		padding: 30px 0 50px;
	}

	&--drop {
		.title-drop {
			margin-top: -30px;
			&__title {
				color: $white;
			}
		}

	}

}

.rates {

	&.parent-close {

		.rates-winner--drop {

			padding-bottom: 0;
		}
		.title-drop {

		}
		.title-drop__text {
			display: block;
		}
        .info-drop {
            display: none;
        }
	}
}
/* Finnish rates winner block */