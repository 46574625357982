.create-way {
    background: $blue1 url('../../../img/create-way-bg.png') no-repeat;
    padding: 44px 0 50px;
    @include b(1600) {
        padding: 30px 0 41px;
    }
    @include b(mobile) {
        padding: 11px 0 20px;
    }
    &__title {
        text-align: center;
        color: $white;
        font: 300 45px $font;
        padding-bottom: 62px;
        @include b(1600) {
            font-size: 36px;
            padding-bottom: 55px;
        }
        @include b(tablet) {
            font-size: 31px;
            text-align: left;
            padding-bottom: 38px;
        }
        @include b(mobile) {
            font-size: 15px;
            padding-bottom: 15px;
        }
    }
    &__flex {
        max-width: 540px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include b(1600) {
            max-width: 280px;
        }
        @include b(tablet) {
            max-width: 100%;
            padding: 0 15px;
            flex-direction: column;
        }
        @include b(mobile) {
            padding: 0;
        }
    }
    &__center {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        &-left {
            position: relative;
            z-index: 1;
        }
        &-middle {
            &:before {
                @include before-img(100%,
                4px);
                background: $white;
                left: 0;
                top: calc(50% - 2px);
            }
        }
        &-right {
            position: relative;
            z-index: 1;
        }
        @include b(tablet) {
            order: 1;
        }
    }
    &__append {
        position: relative;
        display: table;
        &-icon {
            fill: $white;
            background: $green1;
            display: flex;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            border: 5px solid $white;
            &:hover {
                background: $green2;
            }
            &:active {
                background: $green3;
                box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.35);
            }
            @include b(1600) {
                width: 35px;
                height: 35px;
                border-width: 4px;
            }
            @include b(tablet) {
                width: 88px;
                height: 88px;
            }
            @include b(mobile) {
                width: 43px;
                height: 43px;
            }
        }
        &-ico {
            width: 18px;
            height: 18px;
            display: block;
            fill: inherit;
            margin: auto;
            @include b(1600) {
                width: 15px;
                height: 15px;
            }
            @include b(tablet) {
                width: 38px;
                height: 38px;
            }
            @include b(mobile) {
                width: 18px;
                height: 19px;
            }
        }
        &-drop {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: $white;
            white-space: nowrap;
            padding: 11px 16px 9px;
            letter-spacing: -.3px;
            &:before {
                @include before-img(0,
                0);
                border: 7px solid transparent;
                top: calc(50% - 7px);
            }
            &--left {
                margin-right: 20px;
                right: 100%;
                &:before {
                    border-left-color: $white;
                    right: -14px;
                }
            }
            &--right {
                margin-left: 20px;
                left: 100%;
                &:before {
                    border-right-color: $white;
                    left: -14px;
                }
            }
            &-text {
                color: $blue1;
                font: 500 13px $font;
                display: block;
                text-transform: uppercase;
            }
            @include b(1600) {
                padding: 9px 16px 8px;
                &:before {
                    border-width: 6px;
                    top: calc(50% - 6px);
                }
                &--left {
                    margin-right: 15px;
                    &:before {
                        right: -12px;
                    }
                }
                &--right {
                    margin-left: 15px;
                    &:before {
                        left: -12px;
                    }
                }
                &-text {
                    font-size: 10px;
                }
            }
            @include b(tablet) {
                display: none;
            }
        }
    }
    &__truck {
        display: block;
        transform: translateY(-50%);
        @include b(1600) {
            transform: translateY(-100%);
        }
        @include b(tablet) {
            transform: translateY(-3px);
        }
    }
    // left block
    &__left {
        position: relative;
        &:before {
            @include before-img(4px,
            100%);
            background: $white;
            top: 0;
            left: calc(50% - 2px);
        }
        @include b(tablet) {
            order: 0;
            display: flex;
            flex-direction: column-reverse;
            margin-left: 22px;
            align-self: flex-start;
        }
        @include b(mobile) {
            margin-left: 10px;
        }
        .create-way__item {
            &:nth-child(1) {
                padding-top: 50px;
            }
            &:nth-child(2) {
                padding-top: 90px;
            }
            &:nth-child(3) {
                padding-top: 45px;
            }
            @include b(1600) {
                &:nth-child(1) {
                    padding-top: 40px;
                }
                &:nth-child(2) {
                    padding-top: 71px;
                }
                &:nth-child(3) {
                    padding-top: 35px;
                }
            }
            @include b(tablet) {
                &:nth-child(1) {
                    padding-top: 0px;
                    padding-bottom: 117px;
                }
                &:nth-child(2) {
                    padding-top: 0px;
                    padding-bottom: 64px;
                }
                &:nth-child(3) {
                    padding-top: 0px;
                    padding-bottom: 63px;
                }
            }
        }
    }
    // right block
    &__right {
        position: relative;
        &:before {
            @include before-img(4px,
            100%);
            background: $white;
            top: 0;
            left: calc(50% - 2px);
        }
        @include b(tablet) {
            order: 2;
            align-self: flex-end;
            margin-right: 22px;
        }
        @include b(mobile) {
            margin-right: 10px;
        }
        .create-way__item {
            &:nth-child(1) {
                padding-top: 108px;
            }
            &:nth-child(2) {
                padding-top: 120px;
            }
            @include b(1600) {
                &:nth-child(1) {
                    padding-top: 84px;
                }
                &:nth-child(2) {
                    padding-top: 97px;
                }
            }
            @include b(tablet) {
                &:nth-child(1) {
                    padding-top: 71px;
                }
                &:nth-child(2) {
                    padding-top: 104px;
                }
            }
        }
    }
    // all items
    &__item {
        &-box {
            position: relative;
        }
        &-icon {
            display: flex;
            position: relative;
            width: 43px;
            height: 43px;
            background: $white;
            fill: $blue3;
            border-radius: 50%;
            &-ico {
                display: block;
                margin: auto;
                fill: inherit;
            }
            @include b(1600) {
                width: 35px;
                height: 35px;
            }
            @include b(tablet) {
                width: 43px;
                height: 43px;
            }
            @include b(mobile) {
                width: 22px;
                height: 22px;
            }
        }
        &-drop {
            position: absolute;
            top: -5px;
            background: $white;
            display: flex;
            width: 405px;
            &:before {
                @include before-img(0,
                0);
                border: 7px solid transparent;
                top: calc(50% - 7px);
            }
            &--left {
                right: 100%;
                margin-right: 20px;
                &:before {
                    border-left-color: $white;
                    right: -14px;
                }
            }
            &--right {
                left: 100%;
                margin-left: 20px;
                &:before {
                    border-right-color: $white;
                    left: -14px;
                }
                .create-way__item-drop-buttons {
                    order: 1;
                }
                .create-way__item-drop-box {
                    order: 0;
                }
                .create-way__item-drop-address {
                    order: 1;
                    text-align: left;
                    @include b(tablet) {
                        text-align: right;
                    }
                }
                .create-way__item-drop-time {
                    order: 0;
                }
                .create-way__item-drop-box-top {
                    justify-content: flex-start;
                }
                .create-way__item-drop-box-bottom {
                    justify-content: flex-start;
                    @include b(tablet) {
                        justify-content: flex-end;
                    }
                }
            }
            &--large-left {
                &:before {
                    top: 19px;
                    border-left-color: $light;
                }
            }
            &--large-right {
                &:before {
                    top: 19px;
                    border-right-color: $light;
                }
            }
            @include b(1600) {
                width: 325px;
                &:before {
                    border-width: 6px;
                    top: calc(50% - 6px);
                }
                &--left {
                    margin-right: 15px;
                    &:before {
                        right: -12px;
                    }
                }
                &--right {
                    margin-left: 15px;
                    &:before {
                        left: -12px;
                    }
                }
                &--large-left {
                    &:before {
                        top: 19px;
                    }
                }
                &--large-right {
                    &:before {
                        top: 19px;
                    }
                }
            }
            @include b(tablet) {
                flex-direction: row-reverse;
                width: 600px;
                top: -15px;
                &:before {
                    border-width: 12px;
                    top: calc(50% - 12px);
                    border-right-color: $white;
                    border-left-color: transparent;
                }
                &--left {
                    right: auto;
                    left: 100%;
                    margin-left: 20px;
                    margin-right: 0;
                    &:before {
                        left: -24px;
                        right: auto;
                    }
                }
                &--right {
                    left: auto;
                    right: 100%;
                    margin-right: 20px;
                    margin-left: 0;
                    &:before {
                        right: -24px;
                        left: auto;
                    }
                }
                &--large-left {
                    &:before {
                        top: 25px;
                        border-right-color: $light;
                    }
                }
                &--large-right {
                    &:before {
                        top: 25px;
                        border-left-color: $light;
                        border-right-color: transparent;
                    }
                }
            }
            @include b(mobile) {
                width: 400px;
                top: -10px;
                &:before {
                    border-width: 6px;
                    top: calc(50% - 6px);
                }
                &--left {
                    margin-left: 10px;
                    &:before {
                        left: -12px;
                    }
                }
                &--right {
                    margin-right: 10px;
                    &:before {
                        right: -12px;
                    }
                }
                &--large-left {
                    &:before {
                        top: 15px;
                    }
                }
                &--large-right {
                    &:before {
                        top: 15px;
                    }
                }
            }
            @include b(580) {
                width: 280px;
                top: -10px;
                &:before {
                    border-width: 6px;
                    top: calc(50% - 6px);
                }
                &--left {
                    margin-left: 10px;
                    &:before {
                        left: -12px;
                    }
                }
                &--right {
                    margin-right: 10px;
                    &:before {
                        right: -12px;
                    }
                }
                &--large-left {
                    &:before {
                        top: 15px;
                    }
                }
                &--large-right {
                    &:before {
                        top: 15px;
                    }
                }
            }
            @include b(380) {
                width: 240px;
            }
            &-buttons {
                display: flex;
                min-height: 100%;
                width: 100px;
                &-btn {
                    height: 100%;
                    width: 50%;
                }
                @include b(1600) {
                    width: 80px;
                }
                @include b(tablet) {
                    width: 150px;
                    flex-direction: row-reverse;
                }
                @include b(mobile) {
                    width: 75px;
                }
            }
            &-box {
                width: calc(100% - 100px);
                display: flex;
                flex-direction: column;
                &-top {
                    display: flex;
                    justify-content: flex-end;
                    margin: auto 0;
                }
                &-bottom {
                    background: $blue2;
                    padding: 5px 10px;
                    min-height: 39px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                @include b(1600) {
                    width: calc(100% - 80px);
                    &-top {
                        flex-direction: row-reverse;
                    }
                    &-bottom {
                        padding: 13px 15px;
                        justify-content: flex-start;
                    }
                }
                @include b(tablet) {
                    width: calc(100% - 150px);
                }
                @include b(mobile) {
                    width: calc(100% - 75px);
                }
                @include b(580) {
                    &-bottom {
                        align-items: flex-start;
                    }
                }
                @include b(380) {
                    &-bottom {
                        padding: 10px 5px;
                    }
                }
            }
            &-address {
                color: $blue1;
                font: 500 13px $font;
                display: flex;
                text-transform: uppercase;
                text-align: right;
                padding: 11px 13px 5px;
                min-height: 51px;
                align-items: center;
                @include b(1600) {
                    font-size: 10px;
                    min-height: 40px;
                }
                @include b(tablet) {
                    font-size: 18px;
                    text-align: left;
                    min-height: 72px;
                }
                @include b(mobile) {
                    font-size: 12px;
                    min-height: 36px;
                }
            }
            &-time {
                color: $blue1;
                fill: $blue1;
                font: 500 13px $font;
                display: flex;
                align-items: center;
                background: $light;
                padding: 2px 10px;
                &-ico {
                    display: inline-block;
                    vertical-align: middle;
                    fill: inherit;
                    margin-right: 5px;
                }
                &-text {
                    display: inline-block;
                    vertical-align: middle;
                }
                @include b(1600) {
                    font-size: 10px;
                }
                @include b(tablet) {
                    font-size: 19px;
                }
                @include b(mobile) {
                    font-size: 12px;
                }
            }
            &-item {
                fill: $white;
                color: $white;
                font: 500 13px $font;
                display: block;
                text-transform: uppercase;
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
                &-ico {
                    fill: inherit;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }
                &-text {
                    display: inline-block;
                    vertical-align: middle;
                }
                @include b(1600) {
                    font-size: 10px;
                }
                @include b(tablet) {
                    font-size: 18px;
                }
                @include b(mobile) {
                    font-size: 12px;
                }
                @include b(580) {
                    &-ico {
                        display: block;
                        margin: 0 auto;
                    }
                    &-text {
                        display: block;
                        text-align: center;
                    }
                }
                @include b(380) {
                    margin-right: 8px;
                }
            }
        }
        &-text {
            color: $white;
            font: 300 18px $font;
            text-transform: uppercase;
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 15px;
            &--right {
                right: auto;
                left: 100%;
                margin-right: 0;
                margin-left: 15px;
            }
            @include b(1600) {
                font-size: 15px;
            }
            @include b(tablet) {
                display: none;
            }
        }
    }
}