.volma-input {
    display: block;
    position: relative;
    padding-bottom: $errorHeight;
    &--no-padding {
        padding-bottom: 0;
    }
    &__input {
        &--center {
            text-align: center;
        }
        &--text {
            display: flex;
            align-items: center;
        }
        &--no-uppercase {
            text-transform: none;
            font-weight: 400;
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: calc(100% - #{$errorHeight});
        position: absolute;
        top: 0;
        right: 0;
        fill: $white;
        background: $blue1;
        &-ico {
            fill: inherit;
            display: block;
        }
    }
    &--edit {
        margin-bottom: $errorHeight;
        .volma-input__input {
            padding-right: 65px;
        }
    }
    &--error {
        //margin-bottom: 10px;
    }
    &-error {
        position: absolute;
        top: 100%;
        left: 0;
        // margin-top: -27px;
        &__text {
            display: inline-block;
            color: $white;
            font: 400 15px $font;
            background: $red1;
            margin: 0 10px 5px 0;
            padding: 2px 6px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &--more {
        @include b(tablet) {
            .input {
                height: 65px;
                font-size: 16px;
            }
            .volma-placeholder {
                font-size: 16px;
                transform: translateY(23px);
                &.active {
                    font-size: 12px;
                    transform: translateY(8px);
                }
            }
        }
        @include b(mobile) {
            .input {
                height: 40px;
                font-size: 12px;
                border-width: 2px;
            }
            .volma-placeholder {
                font-size: 12px;
                transform: translateY(13px);
                &.active {
                    font-size: 12px;
                    transform: translateY(8px);
                }
            }
        }
    }
    &--button {
        .volma-placeholder {
            font-weight: 300;
            font-style: italic;
        }
        .volma-input__input {
            background: $white;
            padding-right: 80px;
        }
    }
    &__rub {
        margin: 0 10px;
    }
    &__edit {
        position: absolute;
        right: 16px;
        top: calc(50% - 16px);
        @include b(mobile) {
            right: 2px;
        }
    }
    &__ico {
        fill: inherit;
        display: inline-block;
        margin: 0 8px;
    }
    &--blue {
        .volma-input__input {
            color: $white;
            fill: $white;
            border-color: $white;
            background: $blue1;
        }
    }

    &__message-container {
        margin-top: -27px;
        display: flex;
        flex-direction: column;

        .default-input-error {
            margin-top: 0px;
        }
    }

    &__additional-text {
        text-transform: uppercase;
        font: 500 15px "PFDinTextPro";
        margin: 0 10px 5px 0;
        padding: 2px 6px;
    }
}