.report {
    display: flex;
    flex-direction: column;
    &__header {
        position: relative;
    }
    &__delete {
        position: absolute;
        top: 0px;
        right: 0px;
        fill: $gray1;
        &-ico {
            display: block;
            fill: inherit;
            width: 22px;
            height: 22px;
        }
        &:hover {
            opacity: .8;
        }
        @include b(mobile) {
            right: 0px;
            top: 0px;
            &-ico {
                width: 18px;
                height: 18px;
            }
        }
    }
    &__chart {
        canvas {
            @include disableSelect;
        }
    }
}