//----------------------------------------------------//
// Garniture                                          //
//----------------------------------------------------//

//	 Index

// - PFDinTextPro

  // Garniture => PFDinTextPro
  //--------------------------------------------------//
  
//bold
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/bold/PFDinTextPro-Bold.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/bold/PFDinTextPro-Bold.woff') format('woff'), 
  url('../fonts/PFDinTextPro/bold/PFDinTextPro-Bold.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/bold/PFDinTextPro-Bold.svg#PFDinTextPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

//light
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/light/PFDinTextPro-Light.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/light/PFDinTextPro-Light.woff') format('woff'), 
  url('../fonts/PFDinTextPro/light/PFDinTextPro-Light.ttf')  format('truetype'),
  url('../fonts/PFDinTextPro/light/PFDinTextPro-Light.svg#PFDinTextPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

//medium
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/medium/PFDinTextPro-Medium.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/medium/PFDinTextPro-Medium.woff') format('woff'), 
  url('../fonts/PFDinTextPro/medium/PFDinTextPro-Medium.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/medium/PFDinTextPro-Medium.svg#PFDinTextPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

//regular
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/regular/PFDinTextPro-Regular.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/regular/PFDinTextPro-Regular.woff') format('woff'), 
  url('../fonts/PFDinTextPro/regular/PFDinTextPro-Regular.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/regular/PFDinTextPro-Regular.svg#PFDinTextPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

//thin
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/thin/PFDinTextPro-Thin.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-Thin.woff') format('woff'), 
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-Thin.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-Thin.svg#PFDinTextPro-Thin') format('svg');
  font-weight: 200;
  font-style: normal;
}
//thin italic
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/thin/PFDinTextPro-ThinItalic.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-ThinItalic.woff') format('woff'), 
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-ThinItalic.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/thin/PFDinTextPro-ThinItalic.svg#PFDinTextPro-ThinItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

//xblack
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/xBlack/PFDinTextPro-ExtraBlack.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/xBlack/PFDinTextPro-ExtraBlack.woff') format('woff'), 
  url('../fonts/PFDinTextPro/xBlack/PFDinTextPro-ExtraBlack.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/xBlack/PFDinTextPro-ExtraBlack.svg#PFDinTextPro-ExtraBlack') format('svg');
  font-weight: 800;
  font-style: normal;
}

// xthin
@font-face {
  font-family: 'PFDinTextPro';
  src: url('../fonts/PFDinTextPro/xThin/PFDinTextPro-ExtraThin.eot?#iefix') format('embedded-opentype'),  
  url('../fonts/PFDinTextPro/xThin/PFDinTextPro-ExtraThin.woff') format('woff'), 
  url('../fonts/PFDinTextPro/xThin/PFDinTextPro-ExtraThin.ttf')  format('truetype'), 
  url('../fonts/PFDinTextPro/xThin/PFDinTextPro-ExtraThin.svg#PFDinTextPro-XThin') format('svg');
  font-weight: 100;
  font-style: normal;
}
