.volma-light-input {
    position: relative;
    &__placeholder {
        color: $gray2;
        font: 400 20px $font;
    }
    &__input {
        color: $gray2;
        border: 1px solid $gray5;
        height: 80px;
        font: 400 20px $font;
        padding-right: 55px;
    }
    &__ico {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 26px;
        fill: $gray4;
    }
}