.title-drop {
    display: flex;
    justify-content: space-between;
    padding: 25px 42px;
    transition: all $speed;
    margin: -24px -42px 0px;
    align-items: center;
    @include b(1600) {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: -28px;
    }
    @include b(tablet) {
        margin-left: -25px;
        margin-right: -25px;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: -40px;
    }
    @include b(mobile) {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -18px;
        padding: 15px;
    }
    &-parent {
        transition: all $speed;
        &--relative {
            position: relative;
        }
        &.parent-close {
            transition: all $speed;
            margin-bottom: -35px;
            background: none;
            .main-info__item {
                background: none;
                border-bottom: 0;
            }
            @include b(1600) {
                margin-bottom: -28px;
            }
            @include b(tablet) {
                margin-bottom: -44px;
            }
            @include b(mobile) {
                margin-bottom: -20px;
            }
            .title-drop {
                background: $blue1;
                transition: all $speed;
                &__title {
                    color: $white;
                    transition: all $speed;
                }
                &__text {
                    display: block;
                }
            }
            .info-drop {
                display: none;
            }
        }
    }
    &__left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-right: 15px;
    }
    &__right {}
    &__title {
        padding: 0 10px 0 0;
        transition: all $speed;
    }
    &__text {
        color: rgba($light2, .5);
        font: 300 18px $font;
        display: block;
        display: none;
        @include b(mobile) {
            font-size: 12px;
        }
    }
    &__link {
        width: 38px;
        height: 38px;
        background: $blue1;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: $white;
        position: relative;
        cursor: pointer;
        transition: all $speed;
        &:before {
            @include before-img(18px, 3px);
            background: $white;
            left: calc(50% - 9px);
            top: calc(50% - 1.5px);
            transition: all $speed;
        }
        &:after {
            @include before-img(3px, 18px);
            background: $white;
            left: calc(50% - 1.5px);
            top: calc(50% - 9px);
            opacity: 0;
            transition: all $speed;
        }
        &.active {
            background: $white;
            transition: all $speed;
            &:before,
            &:after {
                background: $blue1;
                opacity: 1;
            }
        }
        @include b(mobile) {
            width: 30px;
            height: 30px;
            &:before {
                height: 2px;
                top: calc(50% - 1px);
            }
            &:after {
                width: 2px;
                left: calc(50% - 1px);
            }
        }
    }
}