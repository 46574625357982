.table-box {
    overflow: hidden;
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    &--large {
        overflow: auto;
        max-height: 1247px;
        .table {
            min-width: 1600px;
        }
    }
    &--bottom-padding {
        margin-bottom: $errorHeight;
    }
    @include b(tablet) {
        overflow: auto;
        border: 1px solid $gray5;
        border-right: 0;
        border-bottom: 0;
        &.active {
            overflow: hidden;
        }
        &--large {
            .table {
                min-width: auto;
            }
        }
    }
}