.number-nav {
    background: $blue1;
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 34px);
    width: 30px;
    fill: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    &__link {
        display: block;
        padding: 5px;
        cursor: pointer;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        transition: all $speed;
        transform: translate3d(0, 0, 0);
        &-ico {
            display: block;
            fill: inherit;
            width: 12px;
            height: 6px;
            margin: 0 auto;
            transition: all $speed;
            &:nth-child(2) {
                transform: scale(.8);
                opacity: 0;
            }
            &:nth-child(3) {
                transform: scale(.6);
                opacity: 0;
            }
        }
        &--up {
            transform: rotate(180deg) translate3d(0, 0, 0);
        }
        &:hover:not(.number-nav__link--readonly) {
            background: $blue4;
            transition: all $speed;
            .number-nav__link-ico {
                &:nth-child(2) {
                    opacity: .65;
                }
                &:nth-child(3) {
                    opacity: .35;
                }
            }
        }
        &:active:not(.number-nav__link--readonly) {
                background: $blue5;
            }
        }
        @include b(tablet) {
            width: 40px;
        }
    }