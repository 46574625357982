.table-filter {
    background: $gray3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    left: -100%;
    visibility: hidden;
    opacity: 0;
    transition: all $speed;
    &--range {
        padding-bottom: 50px;
    }
    &.active {
        left: 0;
        visibility: visible;
        opacity: 1;
        transition: all $speed;
    }
    @include b(tablet) {
        padding: 10px 20px;
    }
    @include b(mobile) {
        flex-wrap: wrap;
        .table-filter__left {
            top: 20px;
            left: 20px;
            position: absolute;
        }
        .table-filter__right {
            top: 20px;
            right: 20px;
            position: absolute;
        }
        .table-filter__center {
            width: 100%;
            padding: 0;
        }
    }
    &__left {
        &-ico {
            display: block;
            fill: $white;
        }
    }
    &__right {
        position: relative;
        right: -20px;
        @include b(tablet) {
            right: 0;
        }
    }
    &__center {
        width: 100%;
        padding: 0 30px;
        position: relative;
    }
    &__close {
        display: flex;
        fill: $white;
        border: 1px solid $white;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        cursor: pointer;
        transition: all $speed;
        &-ico {
            fill: inherit;
            display: block;
            margin: auto;
        }
        &:hover {
            transition: all $speed;
            opacity: .8;
        }
        @include b(1600) {
            width: 36px;
            height: 36px;
        }
        @include b(tablet) {
            width: 42px;
            height: 42px;
        }
        @include b(mobile) {
            width: 36px;
            height: 36px;
        }
    }
    &-select {
        position: relative;
    }
    &-text {
        position: relative;
    }
    &__info {
        color: rgba($white, .35);
        font: 300 15px $font;
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 3px;
    }
    .ui-slider {
        &-handle {
            border: 0;
            width: 30px;
            height: 30px;
            background: $light;
            border-radius: 50%;
            top: calc(50% - 15px);
            z-index: 5;
            transform: translateX(-50%);
            &__value {
                color: $white;
                font: 400 25px $font;
                display: block;
                position: absolute;
                top: 100%;
                margin-top: 10px;
                left: 0;
                &:after {
                    content: attr(data-text);
                    color: rgba($white, .35);
                    font: 300 15px $font;
                    display: block;
                    white-space: nowrap;
                }
                &--right {
                    text-align: right;
                    &:after {
                        text-align: right;
                    }
                }
            }
            @include b(1600) {
                width: 20px;
                height: 20px;
                top: calc(50% - 10px);
                &__value {
                    margin-top: 5px;
                    font-size: 18px;
                    &:after {
                        font-size: 12px;
                    }
                    &--right {
                        left: auto;
                        right: 0;
                    }
                }
            }
            @include b(tablet) {
                width: 30px;
                height: 30px;
                top: calc(50% - 15px);
                &__value {
                    margin-top: 10px;
                    font-size: 25px;
                    &:after {
                        font-size: 15px;
                    }
                }
            }
            @include b(mobile) {
                width: 20px;
                height: 20px;
                top: calc(50% - 10px);
                &__value {
                    margin-top: 5px;
                    font-size: 18px;
                    &:after {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .ui-widget.ui-widget-content {
        border: 1px solid $light;
    }
    .ui-slider-horizontal .ui-slider-range {
        height: 6px;
        background: $light;
        top: calc(50% - 3px);
        border-radius: 0;
        @include b(1600) {
            height: 4px;
            top: calc(50% - 2px);
        }
    }
    &-range {}
    &-date {
        margin: 0 -15px;
        display: flex;
        &__date {
            position: relative;
        }
        &__cell {
            width: 50%;
            padding: 0 15px;
        }
        @include b(mobile) {
            flex-direction: column;
            &__cell {
                width: 100%;
                padding-bottom: 35px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    .default-select-wrapper {
        padding-bottom: 0;
        .select{
            border:0;
            padding: 0;
            height: initial;
        }
    }
}