.more-info {
    // padding: 32px 0 39px;
    // @extend %reset-block;
    // background: $gray4;
    // border-bottom: 1px solid $gray5;
    // @include b(1600) {
    //     padding: 25px 0 30px;
    // }
    // @include b(mobile) {
    //     padding: 20px 0 22px;
    // }
    // &__top {
    //     display: flex;
    //     align-items: center;
    //     padding-bottom: 28px;
    //     @include b(1600) {
    //         padding-bottom: 22px;
    //     }
    //     @include b(mobile) {
    //         padding-bottom: 15px;
    //     }
    // }
    // &__title {
    //     padding: 0;
    //     padding-right: 28px;
    //     @include b(tablet) {
    //         width: 50%;
    //     }
    // }
    // &__add {
    //     width: auto;
    //     @include b(tablet) {
    //         width: 50%;
    //     }
    // }
    &__box {}
    &__row {
        display: flex;
        margin-bottom: -2px;
        @include b(mobile) {
            margin-bottom: 0px;
        }
    }
    &__cell {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        .input {
            border-right-width: 0;
        }
        &:last-child {
            .input {
                border-right-width: 2px;
            }
        }
        &--large {
            width: 50%;
        }
        &--small {
            width: 60px;
        }
        .volma-input {
            padding-bottom: 0;
        }
    }
    &__redact {
        display: flex;
        height: 100%;
        border: 2px solid $blue1;
        &-link {
            width: 50%;
        }
    }
}