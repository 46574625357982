.search {
    background: $gray3;
    width: 100%;
    z-index: 10;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    min-height: 128px;
    transition: all $speed;
    @include b(tablet) {
        padding: 10px 20px;
    }
    @include b(mobile) {
        min-height: 96px;
    }
    &__left {
        &-ico {
            display: block;
            fill: $white;
        }
    }
    &__right {
        position: relative;
        right: -20px;
        @include b(tablet) {
            right: 0;
        }
    }
    &__center {
        width: 100%;
        padding: 0 30px;
        position: relative;
    }
    &__rebuild {
        display: flex;
        fill: $white;
        border: 1px solid $white;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        cursor: pointer;
        transition: all $speed;
        &-ico {
            fill: inherit;
            display: block;
            margin: auto;
        }
        &:hover {
            transition: all $speed;
            opacity: .8;
        }
        @include b(1600) {
            width: 36px;
            height: 36px;
        }
        @include b(tablet) {
            width: 42px;
            height: 42px;
        }
        @include b(mobile) {
            width: 36px;
            height: 36px;
        }
    }
}