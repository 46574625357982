.search-header {
    display: flex;
    border-bottom: 1px solid $gray6;
    &__input {
        color: $gray2;
        fill: $gray2;
        font: 200 16px/1.2 $font;
        display: block;
        text-decoration: none; // border: 1px solid transparent; // padding: 5px 11px;
        border: none;
        padding: 5px 11px;
        transition: none;
        @include b(1600) {
            font-size: 15px;
        }
        @include b(tablet) {
            font-size: 24px;
        }
        @include b(mobile) {
            font-size: 13px;
        }
    }
    &__right {
        display: block;
        &-link {
            position: relative;
            width: 30px;
            height: 30px; // border-radius: 50%;
            // border: 1px solid $gray6;
            fill: $gray6;
            display: block;
            &-ico {
                position: absolute;
                bottom: 0;
                top: 0;
                right: 0;
                left: 0;
                margin: auto;
                fill: inherit;
                display: block;
            }
            &:hover {
                background: $white;
                opacity: 1;
                border-color: $white;
            }
            @include b(1600) {
                width: 30px;
                height: 30px;
            }
            @include b(tablet) {
                width: 38px;
                height: 38px;
            }
            @include b(mobile) {
                width: 25px;
                height: 25px;
            }
        }
    }
}