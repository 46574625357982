.inform {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: 10px 0 5px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all $speed;
    visibility: hidden;
    transform: translateY(-100%);
    &--new {
        background: $blue2;
        z-index: 9997;
    }
    &--validation {
        background: $orange1;
        z-index: 9998;
    }
    &--error {
        background: $red1;
        .inform__list-val {
            border: none;
        }
        z-index: 9999;
    }
    &.active {
        transition: all $speed;
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &.collapsed {
        transition: all $speed;
        opacity: 1;
        visibility: visible;
        top: 21px;
        transform: translateY(-100%);
        @include b(tablet) {
            top: 41px;
        }
        @include b(mobile) {
            top: 21px;
        }
    }
    @include b(tablet) {
        // flex-direction: column;
        align-items: stretch;
        padding-bottom: 0;
        &--new {
            // flex-direction: row;
            align-items: center;
            .inform__left {
                // padding-right: 20px;
            }
            .inform__right {
                margin: 0;
            }
        }
    }
    @include b(mobile) {
        padding: 10px 0 0 20px;
    }
    &__left {
        flex: 1;
        max-width: 100%;
        overflow: auto;
        padding-right: 40px + 10px + 22px;
        max-height: 250px; //margin-right: 10px;
    }
    &__right {
        flex: 0;
        position: absolute;
        right: 30px;
        @include b(tablet) {
            width: auto; // margin: 10px -40px 0;
        }
        @include b(mobile) {
            // margin: 10px -20px 0;
            right: 20px;
        }
    }
    &__collapse {
        flex: 0;
        position: absolute;
        right: 0px;
        bottom: 0;
    }
    &__top {
        padding-bottom: 5px;
    }
    &__title {
        color: $white;
        font: 300 25px/1 $font;
        display: inline-block;
        vertical-align: middle;
        @include b(tablet) {
            font-size: 36px;
        }
        @include b(tablet) {
            font-size: 25px;
        }
    }
    &__accept {
        fill: $white;
        background: $orange1;
        color: $white;
        font: 400 12px $font;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        padding: 1px 10px;
        margin-left: 4px;
        &-ico {
            display: inline-block;
            vertical-align: middle;
            fill: inherit;
            position: relative;
            top: -5px
        }
        &-text {
            display: inline-block;
            vertical-align: middle;
        }
        @include b(tablet) {
            font-size: 16px;
        }
        @include b(mobile) {
            font-size: 12px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        &-item {
            display: flex;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        &-val {
            border: 1px solid $white;
            color: $white;
            font: 400 12px $font;
            text-transform: uppercase;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            &--green {
                background-color: $green2;
            }
            &--red {
                background-color: $red2;
            }
        }
        &-link {
            color: $gray12;
            font: 400 12px $font;
            background: $white;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            &--orange {
                fill: $white;
                background: $orange1;
                color: $white;
                margin-left: -1px;
                border: 1px solid white;
            }
            &:hover {
                opacity: .8;
            }
        }
        @include b(tablet) {
            &-val {
                font-size: 16px;
                padding: 10px 15px;
            }
            &-link {
                font-size: 16px;
                padding: 10px 15px;
            }
        }
        @include b(mobile) {
            &-val {
                font-size: 12px;
                padding: 2px 10px;
            }
            &-link {
                font-size: 12px;
                padding: 2px 10px;
            }
        }
    }
    &__bg-ico {
        width: 47px;
        height: 47px;
        display: block;
        position: absolute;
        top: calc(50% - 23.5px);
        left: 20px;
        fill: rgba($white, .15);
    }
    &__close {
        display: block;
        fill: $white;
        transition: all $speed;
        cursor: pointer;
        &-ico {
            width: 22px;
            height: 22px;
            fill: inherit;
            display: block;
        }
        &:hover {
            opacity: .8;
        }
    }
    &-error {
        background: $red1;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        min-width: 280px;
        justify-content: space-between;
        &__box {
            padding-right: 10px;
        }
        &__title {
            color: $white;
            font: 400 14px $font;
            text-transform: uppercase;
            display: block;
        }
        &__text {
            color: $white;
            font: 300 12px $font;
            font-style: italic;
            text-transform: uppercase;
            display: block;
        }
        @include b(tablet) {
            padding: 10px 40px;
            &__title {
                font-size: 25px;
            }
            &__text {
                font-size: 16px;
            }
        }
        @include b(mobile) {
            padding: 10px 20px;
            &__title {
                font-size: 14px;
            }
            &__text {
                font-size: 12px;
            }
        }
    }
}