.nav {
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 0 40px;
    width: calc(100% - 227px - 355px);
    flex-direction: column;
    justify-content: center;
    @include b(1600) {
        width: calc(100% - 170px - 115px);
        padding: 0 30px;
    }
    @include b(tablet) {
        width: 100%;
        order: 3;
        text-align: center;
        padding: 52px 30px;
    }
    @include b(mobile) {
        padding: 25px 15px;
    }
    &__list {
        width: 100%;
        padding-bottom: 10px;
        &--light {
            .nav__list-link {
                font-weight: 200;
            }
        }
        &:last-child {
            padding-bottom: 0;
        }
        &--reduced {
            .nav__list-item {
                margin-right: 23px;
            }
        }
        &-item {
            display: inline-block;
            margin-right: 36px;            
            &:last-child {
                margin-right: 0;
            }
            &--submenu {
                position: relative;
                transition: all $speed;
                .nav__list-link {
                    position: relative;
                    padding-right: 31px;
                }
                &.active {
                    z-index: 10;
                    .nav__list-link {
                        color: $white;
                        text-decoration: none;
                        fill: $white;
                        text-shadow: 0px 0px 1px $white;
                        position: relative;
                        z-index: 10;
                        &-ico {
                            transform: rotate(180deg);
                            right: auto;
                            left: 200px;
                            @include b(mobile) {
                                left: 158px;
                            }
                        }
                    }
                }
            }
            @include b(1600) {
                margin-right: 20px;
            }
            @include b(tablet) {
                margin-right: 35px;
                margin-bottom: 8px;
                &--submenu {
                    margin-right: 80px;
                }
            }
            @include b(mobile) {
                margin-right: 10px;
                margin-bottom: 4px;
                &--submenu {
                    margin-right: 60px;
                }
            }
        }
        &-link {
            color: $gray2;
            fill: $gray2;
            font: 400 16px/1.2 $font;
            display: block;
            text-decoration: underline;
            border: 1px solid transparent;
            padding: 5px 11px;
            transition: none;
            &-ico {
                display: block;
                width: 10px;
                height: 6px;
                position: absolute;
                right: 11px;
                top: calc(50% - 3px);
                fill: inherit;
                transition: all $speed;
            }
            &.active {
                border: 1px solid $gray2;
                text-decoration: none;
            }
            &:hover {}
            @include b(1600) {
                font-size: 15px;
            }
            @include b(tablet) {
                font-size: 24px;
                font-weight: 500;
            }
            @include b(mobile) {
                font-size: 13px;
            }
        }
    }
    &-drop {
        @extend .custom-select__drop;
        position: absolute;
        top: -7px;
        width: 225px;
        padding: 0 11px;
        visibility: hidden;
        opacity: 0;
        transition: all $speed;
        padding-top: 32px;
        &.active {
            opacity: 1;
            visibility: visible;
            transition: all $speed;
        }
        @include b(tablet) {
            padding-top: 40px;
        }
        @include b(mobile) {
            padding-top: 32px;
            width: 180px;
        }
        &__list {
            &-item {
                @extend .custom-select__drop-item;
                border-width: 1px;
                text-align: left;
            }
            &-link {
                @extend .custom-select__drop-link;
                text-transform: none;
                padding: 8px 11px;
                margin: 0 -11px;
                @include b(tablet) {
                    font-size: 20px;
                }
                @include b(mobile) {
                    font-size: 13px;
                }
            }
        }
    }
}