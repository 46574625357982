.dark-select {
    position: relative;
    display: block;
    &__ico {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        fill: $blue1;
    }
    &__select {
        position: relative;
        z-index: 1;
    }
    &__arrow {
        position: absolute;
        right: 17px;
        top: calc(50% - 5px);
        fill: $blue1;
        width: 18px;
        height: 10px;
    }
    &--ico {
        .dark-select__select {
            // padding-left: 56px;
            text-indent: 38px;
        }
    }
    @include b(mobile) {
        &__arrow {
            width: 12px;
            height: 8px;
            top: calc(50% - 4px);
        }
    }
}

.dark-select {
    min-height: 50px;
    &.Select,
    &.Select.is-open {
        .Select-control {
            border: 2px solid #ededed;
            height: 50px;
            border-radius: 0;
            background: #fff;
            font: 500 15px "PFDinTextPro";
            color: #ccc;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            padding: 0 0 0 10px;
            .Select-input {
                padding-top: 4px;
            }
        }
        .Select-placeholder {
            color: rgba(255, 255, 255, .5);
            font: 300 23px/46px "PFDinTextPro";
            font-style: italic;
            padding-right: 55px;
            padding-left: 28px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
    &.Select.is-open {
        .Select-arrow-zone {
            .dark-select__arrow {
                fill: #4a80c1;
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    &.Select {
        .Select-arrow-zone {
            width: 50px;
            height: 100%;
            border-left: 2px solid #ededed;
            .dark-select__arrow {
                fill: #4a80c1;
            }
        }
        .Select-menu-outer {
            background: #4a80c1;
            .VirtualizedSelectOption {
                padding: 0 18px;
                &:first-child {
                    .custom-select__drop-item {
                        border-top: 0px solid #fff;
                    }
                }
                .custom-select__drop-item {
                    border-top: 2px solid #fff;
                }
                .custom-select__drop-link {
                    color: #fff;
                    font: 300 15px "PFDinTextPro";
                    font-style: italic;
                    text-transform: uppercase;
                    display: block;
                    padding: 23px 18px;
                    margin: 0 -18px;
                    &:hover {
                        background: #5f8ec7;
                    }

                    @include drop-label-svg; 
                }
            }
        }
        &--multi {
            .Select-value {
                background: $blue1;
                border-radius: 0;
                font-size: 0;
                line-height: 1;
                margin: 10px 0 0 10px;
                &-icon {
                    border: 0;
                    color: $white;
                    border-radius: 0;
                    font-size: 20px;
                    transition: all .3s;
                    &:hover {
                        color: rgba($white, .5);
                    }
                }
                &-label {
                    color: $white;
                    font: 400 15px $font;
                    border-radius: 0;
                }
                @include b(1600) {
                    margin: 5px 0 0 5px;
                }
                @include b(tablet) {
                    margin: 10px 0 0 10px;
                }
                @include b(mobile) {
                    margin: 5px 0 0 5px;
                }
            }
            .Select-clear-zone {
                padding-right: 10px;
            }
        }
    }
}