.history-status {
    padding: 37px 0 41px;
    border-bottom: 1px solid $gray5;
    &__title {
        padding-bottom: 28px;
    }
    @include b(1600) {
        padding: 26px 0 30px;
    }
    @include b(tablet) {
        padding: 26px 0 42px;
    }
    @include b(mobile) {
        padding: 12px 0 15px;
        &__title {
            padding-bottom: 15px;
        }
    }
}