.table {
    text-align: left;
    @extend %reset-block;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    @include b(tablet) {
        flex-direction: row;
        position: relative;
    }
    &__head {
        display: flex;
        flex-direction: column;
        position: relative;        
        &-left {
            display: flex;
            vertical-align: middle;
            align-items: center;
            width: calc(100% - 10px);
            padding-right: 15px;
            cursor: pointer;
            height: 100%           
        }
        @include b(mobile) {
            &-left {
                width: calc(100% - 20px);
            }            
        }
        @include b(1600) {
            &-left {
                width: calc(100% - 15px);
            }            
        }
        &-right {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
        }
        &-title {
            color: $white;
            font: 500 18px $font;
            display: block;
            padding: 0; //margin: auto 0;
        }
        &-text {
            color: rgba($white, .35);
            font: 200 13px $font;
            display: block;
        }
        
        &-link {
            @include header-button;          
        }
        &-filter {
            @include header-button;

            &-ico {
                &--asc {
                    transition: transform .3s ease-in-out;
                    transform: rotate(180deg);
                }
                &--desc {
                    transition: transform .3s ease-in-out;
                    transform: rotate(0deg);
                }
            }
            
           
        }
        @include b(1600) {
            &-title {
                font-size: 14px;
            }
            &-text {
                font-size: 12px;
            }
        }
        @include b(tablet) {
            width: 250px; //calc(50vw - 34px);
            position: static;
            &-title {
                font-size: 18px;
            }
            &-text {
                font-size: 13px;
            }
        }
        @include b(mobile) {
            width: 250px; //calc(50vw - 24px);
            &-title {
                font-size: 16px;
            }
            &-text {
                font-size: 13px;
            }
        }
        @include b(580) {
            width: 250px; //calc(100vw - 48px);
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        .table__cell {
            display: flex;
            align-items: flex-start;
        }
        @include b(tablet) {
            // width: calc(50vw - 34px);
            flex-direction: row;
        }
        @include b(mobile) {
            // width: calc(50vw - 24px);
        }
        @include b(580) {
            // width: calc(100vw - 48px);
        }
    }
    &__row {
        display: flex;
        &--head {}
        &--mobile * {
            background-color: #ffa600 !important;
        }
        &--body {
            .table__cell {
                position: relative;
            }
            &:hover {
                transition: background $speed;
                .table__cell {
                    background: $white;
                    &--orange {
                        background: $orange1;
                    }
                    &--green {
                        background: $green1;
                    }
                    &--selected {
                        background: rgba($blue1, .9);
                    }
                }
            }
        }
        &--bet-exceeded-rate,
        &--failed,
        &--finished,
        &--bet-contains {
            transition: background $speed;
            position: relative;
            &:before {
                content: "окончено";
                color: rgba($white, .13);
                font: 800 100px/1 $font;
                text-transform: uppercase;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                transform: translateY(-50%);
                @include b(tablet) {
                    transform: rotate(-90deg) translate(40%, -260px);
                    bottom: 0;
                    right: auto;
                    top: auto;
                    left: 0;
                    font-size: 200px;
                    line-height: 200px;
                    height: 200px;
                    max-height: 200px;
                    min-height: 200px;
                }
            }
            .table__cell:not(.table__cell--selected) {
                background: $red1;
            }
            .table-cont {
                color: $white;
                fill: $white;
                position: relative;
                z-index: 1;
            }
            .table-cont__count {
                color: $white;
                fill: $white;
                border-color: $white;
            }
            &:hover {
                .table__cell:not(.table__cell--selected) {
                    background: rgba($red1, .9);
                }
            }
        }
        &--failed {
            &:before {
                content: "failed";
            }
        }
        &--bet-exceeded-rate{
            .table__cell:not(.table__cell--selected) {
                background: $green1;
            }
            &:before {
                content: "превышение ставки";
            }
            &:hover {
                .table__cell:not(.table__cell--selected) {
                    background: rgba($green1, .9);
                }
            }
        }
        &--bet-contains{
            .table__cell:not(.table__cell--selected) {
                background: $blue2;
            }
            &:before {
                content: "имеются ставки";
                @include b(mobile) {
                    transform: rotate(-90deg) translate(40%, -310px);
                    content: "ставки";
                }
            }
            &:hover {
                .table__cell:not(.table__cell--selected) {
                    background: rgba($blue2, .9);
                }
            }
        }
        &--green {
            transition: background $speed;
            position: relative;
            &:before {
                content: attr(data-text);
                color: rgba($white, .13);
                font: 800 100px/1 $font;
                text-transform: uppercase;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                transform: translateY(-50%);
                @include b(tablet) {
                    transform: rotate(-90deg) translate(50%, 0%);
                    bottom: 0;
                    right: 0;
                    top: auto;
                    left: auto;
                }
            }
            .table__cell {
                background: $green1;
            }
            .table-cont {
                color: $white;
                fill: $white;
                position: relative;
                z-index: 1;
            }
            .table-cont__count {
                color: $white;
                fill: $white;
                border-color: $white;
            }
            &:hover {
                .table__cell {
                    background: $green1;
                }
            }
        }
        &--orange {
            transition: background $speed;
            position: relative;
            &:before {
                content: attr(data-text);
                color: rgba($white, .13);
                font: 800 100px/1 $font;
                text-transform: uppercase;
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 1;
                transform: translateY(-50%);
                @include b(tablet) {
                    transform: rotate(-90deg) translate(50%, 0%);
                    bottom: 0;
                    right: 0;
                    top: auto;
                    left: auto;
                }
            }
            .table__cell {
                background: $orange1;
            }
            .table-cont {
                color: $white;
                fill: $white;
                position: relative;
                z-index: 1;
            }
            .table-cont__count {
                color: $white;
                fill: $white;
                border-color: $white;
            }
            &:hover {
                .table__cell {
                    background: $orange1;
                }
            }
        }
        @include b(tablet) {
            flex-direction: column;
            &--head {
                width: 100%;
            }
            &--body {}
        }
    }
    &__cell {
        padding: 28px 39px 28px; // width: 19%;
        border-right: 1px solid $gray5;
        border-bottom: 1px solid $gray5;
        background: $gray4; //transition: all $speed;
        min-height: 92px;
        overflow: auto;
        overflow-x: hidden;
        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: 0;
        }
        &--head {
            background: $gray3;
            padding: 22px 24px 22px;
            border-top: 0;
            display: flex;
            align-items: center;
            min-height: 128px;
        }
        &--border {
            border-right: 1px solid $gray5;
            border-bottom: 1px solid $gray5;
            &:first-child {
                border-left: 1px solid $gray5;
            }
            &:last-child {
                border-right: 1px solid $gray5;
            }
        }
        &--orange {
            background: $orange1;
            .table-cont {
                color: $white;
                fill: $white;
            }
            .set-wager {
                border-left: 1px solid $white;
            }
        }
        &--green {
            background: $green1;
            .table-cont {
                color: $white;
                fill: $white;
            }
            .set-wager {
                border-left: 1px solid $white;
            }
        }
        &--multi {
            flex-direction: column;
            .table-cont {
                padding-bottom: 15px;
                align-items: flex-start;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        &--route {
            position: relative;
            &:before {
                @include before-img(100%, 100%);
                top: 0;
                left: 0;
                background: url('../../../img/table-route-bg.png') center repeat-x;
                background-size: contain;
                z-index: 2;
            }
        }
        &--selected {
            background: $blue1;
            .table-cont {
                color: $white;
                fill: $white;
            }
            .set-wager {
                border-left: 1px solid $white;
            }
            .table-cont__count {
                color: $white;
                fill: $white;
                border-color: $white;
            }

            .table-cont__text--link {
                color: $white;
            }

        }
        &--hiddenOverflow {
            overflow: hidden;
        }
        &--white {
            background: $white;
        }
        @include b(1600) {
            padding: 10px 15px 10px;
            min-height: 75px;
            &--head {
                min-height: 102px;
            }
        }
        @include b(tablet) {
            width: 100%;
            width: calc(50vw - 34px);
            &:last-child {
                border-right: 1px solid $gray5;
            }
            &--border {
                &:first-child {
                    border-left: 0;
                }
            }
        }
        @include b(mobile) {
            // width: calc(50vw - 24px);
            width: 50vw;
        }
        @include b(580) {
            // width: calc(100vw - 48px);
            width: 50vw;
        }
    }
    &-cont {
        fill: $blue3;
        color: $gray1;
        font: 400 14px/1.2 $font;
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin: auto 0;
        z-index: 3;
        &--disabled {
            fill: $gray7;
        }
        &__ico {
            display: inline-block;
            vertical-align: middle;
            fill: inherit;
            margin-right: 8px;
        }
        &__text {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 35px);
            &--link {
                color: $blue1;
                text-decoration: underline;
                flex: 0 1 auto;
                width: auto;
                vertical-align: baseline;
                &:hover {
                    text-decoration: none;
                }
            }
            &--hasIcon {
                width: calc(100% - 55px);
            }
        }
        &__link {
            display: inline-block;
        }
        &__pay {}
        &__box {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
        &__highlight {
            color: #fff;
            background-color: $blue1;
            display: inline-block;
            padding: 0 5px;
            margin-bottom: 4px;
        }
        &__search-field-name {
            font-weight: 500;
        }
        &__count {
            position: relative;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid $blue1;
            fill: $blue1;
            display: block;
            margin-right: 8px;
            line-height: 21px;
            color: $blue1;
            font-weight: 500;
            text-align: center;
            align-self: flex-start;
        }
        &--wager {
            width: calc(100% - 105px);
        }
        .input {
            font: 400 14px/1.2 $font;
            height: 36px;
            min-height: 36px;
            border-width: 1px;
        }
        .volma-number {
            .volma-number__value {
                margin-left: -1px;
            }
            .volma-number__unit {
                margin-left: -1px;
            }
        }
        .number-nav {
            width: 24px;
        }
        .default-input-error {
            margin-top: -31px;
            &__text {
                font: 400 12px/1.2 $font;
            }
        }
        &--wrap {
            flex-wrap: wrap;
        }
        @include b(1600) {
            font-size: 14px;
        }
        @include b(tablet) {
            font-size: 14px;
        }
        @include b(mobile) {
            font-size: 14px;
        }
    }
    &__wager {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 103px;
    }
    &--min-padding {
        .table__cell {
            padding-left: 17px;
            padding-right: 17px;
        }
    }
}