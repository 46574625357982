.volma-placeholder {
    position: absolute;
    top: 0;
    transform: translateY(23px); //19 textarea
    color: $gray1;
    font: 500 16px $font;
    text-transform: uppercase;
    left: 18px;
    transition: all $speed;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 50px;
    &--edit {
        font-style: italic;
        font-weight: 300;
    }
    &.active {
        transform: translateY(6px);
        transition: all $speed;
        font-size: 11px;
        color: rgba($gray1, .6);
    }
    &--center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        &.active {
            top: 0;
            transform: translate(-50%, 6px);
            transition: all $speed;
            font-size: 11px;
            color: rgba($gray1, .6);
        }
    }
    @include b(1600) {
        font-size: 13px;
        transform: translateY(19px);
        &.active {
            transform: translateY(6px);
        }
    }
    @include b(tablet) {
        font-size: 20px;
        transform: translateY(31px);
        &.active {
            transform: translateY(15px);
            font-size: 12px;
        }
    }
    @include b(mobile) {
        font-size: 12px;
        transform: translateY(14px);
        &.active {
            opacity: 0;
            visibility: hidden;
        }
    }
}