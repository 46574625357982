@mixin drop-label-svg {
    .custom-select__drop-label {   
        white-space: nowrap;
        
        svg {
            fill: #fff;
            height: 27px;
            width: 27px;
            margin-top: -6px;
        }
    }
}