#react-select-2--backspace-remove-message {
    display: none;
}

.default-select-wrapper {
    position: relative;
    display: block;
    padding-bottom: $errorHeight;

    span.Select-value-label {
        max-width: 455px;
        overflow: hidden;
        text-overflow: ellipsis;
        
        svg.table-cont__ico {
            fill: #fff;
            margin-top: -3px;
            width: 26px;
            height: 26px;
        }                
    }
}

.default-select-outer {
    position: relative;
}

.default-select {
    position: relative;
    display: block;
    &__ico {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 17px;
        fill: $blue1;
    }
    &__select {
        position: relative;
        z-index: 1;
    }
    &__arrow {
        // position: absolute;
        // right: 0px;
        // top: calc(50% - 5px);
        fill: $blue1;
        width: 18px;
        height: 10px;
    }
    &--ico {
        .default-select__select {
            // padding-left: 56px;
            text-indent: 38px;
        }
    }
    @include b(1600) {
        &__arrow {
            width: 15px;
            height: 8px; // top: calc(50% - 4px);
        }
    }
    @include b(tablet) {
        &__arrow {
            width: 18px;
            height: 10px; // top: calc(50% - 4px);
        }
    }
    @include b(mobile) {
        &__arrow {
            width: 12px;
            height: 8px; // top: calc(50% - 4px);
        }
    }
}

.default-select {
    padding-left: 0px;
    display: flex;
    align-items: center;
    &.Select,
    &.Select.is-open {
        .Select-control {
            background: none; // font: 500 15px "PFDinTextPro";
            line-height: 1;
            text-transform: uppercase;
            color: #7b8693;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0; // border: 2px solid #4a80c1;
            padding-left: 7px;

            .Select-input {
                padding-top: 4px;
            }

        }
        .Select-value,
        .Select-placeholder {
            line-height: 1;
            color: inherit;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            outline: none;
            white-space: break-spaces;            
        }
    }
    &.Select.is-open {
        .Select-arrow-zone {
            .default-select__arrow {
                // fill: #fff;
                -webkit-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    &.Select {
        .Select-arrow-zone {
            border-left: 0;
            padding-right: 0;
            vertical-align: middle;
        }
        .Select-menu-outer {
            background: #4a80c1;
            .VirtualizedSelectOption {
                padding: 0 18px;
                &:first-child {
                    .custom-select__drop-item {
                        border-top: 2px solid transparent;
                    }
                }
                .custom-select__drop-item {
                    border-top: 2px solid #fff;
                }
                
                .custom-select__drop-link {
                    color: #fff;
                    font: 300 15px "PFDinTextPro";
                    font-style: italic;
                    text-transform: uppercase;
                    display: block;
                    padding: 23px 18px;
                    margin: 0 -18px;

                    &:hover {
                        background: #5f8ec7;
                    }                    
                     
                    @include drop-label-svg;
                }
                &.VirtualizedSelectFocusedOption {
                    background: #5f8ec7;
                }
            }
        }
        &--multi {
            .Select-value {
                background: $blue1;
                border-radius: 0;
                font-size: 0;
                line-height: 1;
                margin: 5px 0 5px 10px;
                padding: 0;

                &-icon {
                    border: 0;
                    color: $white;
                    border-radius: 0;
                    font-size: 20px;
                    line-height: 1;
                    transition: all .3s;
                    &:hover {
                        color: rgba($white, .5);
                    }
                }
                &-label {
                    color: $white;
                    font: 400 15px $font;
                    line-height: 1.3;
                    border-radius: 0;
                }
                @include b(1600) {
                    margin: 3px 0 2px 5px;
                    &-icon {
                        font-size: 17px;
                    }
                    &-label {
                        font-size: 13px;
                    }
                }
                @include b(tablet) {
                    margin: 5px 0 5px 10px;
                    &-icon {
                        font-size: 23px;
                    }
                    &-label {
                        font-size: 20px;
                    }
                }
                @include b(mobile) {
                    margin: 3px 0 2px 5px;
                    &-icon {
                        font-size: 15px;
                    }
                    &-label {
                        font-size: 12px;
                    }
                }
            }
            .Select-multi-value-wrapper {
                display: flex;
                flex: 1;
                align-items: center;
                flex-wrap: wrap;
            }
        }
    }
}