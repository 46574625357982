.create-card {
    background: $gray3;
    position: relative;
    @extend %reset-block;
    padding: 21px 0 10px;
    min-height: 100px;
    &:before {
        @include before-img(100%, 100%);
        top: 0;
        left: 0;
        background: url('../../../img/create-card-bg.png') center no-repeat;
        background-size: cover;
    }
    &--no-bg{
        &:before {
            display: none;
        }
        z-index: -1;
    }
    @include b(1600) {
        padding: 25px 0 20px;
    }
    @include b(tablet) {
        padding: 0;
        min-height: 150px;
    }
    @include b(mobile) {
        padding: 0;
        min-height: 80px;
    }
    &__form {
        position: relative;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        &--padding-top {
            padding-top: 8px;
            padding-bottom: 0px;
        }
        @include b(1600) {
            padding-bottom: 14px;
            &--padding-top {
                padding-top: 4px;
                padding-bottom: 0px;
            }
        }
        @include b(tablet) {
            padding: 0;
            min-height: 150px;
        }
        @include b(mobile) {
            padding: 0;
            min-height: 80px;
        }
    }
    &__title {
        color: $white;
        font: 700 23px $font;
        display: flex;
        align-items: center;
        fill: $blue2;
        text-transform: uppercase;
        padding: 0;
        &-ico {
            display: inline-block;
            vertical-align: middle;
            fill: inherit;
            position: relative;
            z-index: 2;
            &-bg {
                fill: rgba($white, .09);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: auto;
            }
        }
        &-icon {
            position: relative;
            margin-right: 10px;
        }
        &-text {
            display: inline-block;
            vertical-align: middle;
        }
        @include b(1600) {
            font-size: 20px;
        }
        @include b(tablet) {
            font-size: 31px;
            &-ico {
                // margin-right: 18px;
            }
        }
        @include b(mobile) {
            font-size: 15px;
            &-ico {
                margin-right: 10px;
            }
        }
    }
    &__buttons {
        @include b(tablet) {
            display: flex;
            flex-direction: column;
            margin-right: -25px;
            min-width: 180px;
            align-self: flex-start;
        }
        @include b(mobile) {
            min-width: 90px;
            margin-right: -15px;
        }
    }
    &__link {
        width: auto;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        height: 41px;
        &:last-child {
            margin-right: 0;
        }
        @include b(1600) {
            height: 33px;
        }
        @include b(tablet) {
            height: auto;
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 75px;
            align-items: center;
            overflow: hidden;
            .btn__text {
                float: left;
            }
            .btn__ico {
                float: right;
            }
            &:first-child {
                order: 1;
            }
            &:last-child {
                order: 0;
            }
        }
        @include b(mobile) {
            height: 37px;
        }
    }
    &__input {}
    &__row {
        padding-left: 42px;
        margin: 0 -25px;
        @include b(1600) {
            padding-left: 0;
        }
        @include b(tablet) {
            background: rgba($gray3, .75);
            padding: 26px 0 28px;
        }
        @include b(mobile) {
            margin: 0 -15px;
            padding: 11px 0 12px;
        }
    }
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 25px;
        fill: $blue2;
        &--25 {
            width: 25%;
        }
        &--text {
            color: $white;
            font: 500 36px $font;
            text-transform: uppercase;
        }
        @include b(1600) {
            width: 100%;
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        @include b(tablet) {
            padding-bottom: 18px;
        }
        @include b(mobile) {
            padding: 0 15px 10px;
        }
    }
    &__cell {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 0 25px;
        &--25 {
            width: 25%;
        }
        @include b(1600) {
            width: 100%;
            padding-bottom: 10px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        @include b(tablet) {
            padding-bottom: 18px;
        }
        @include b(mobile) {
            padding: 0 15px 10px;
        }
    }
    &__box {
        padding-bottom: 2px;
    }
    &__desc {}
}