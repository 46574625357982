//----------------------------------------------------//
// Variables                                          //
//----------------------------------------------------//
$mainFont:				'PFDinTextPro'; 
$mainFontSize:		18px;
$mainFontHeight:	24px;
$mainFontWeight:	400;
$mainFontColor:		#000;

$secondFont:				'Verdana'; 
$secondFontSize:		14px;
$secondFontHeight:	22px;
$secondFontWeign:		400;
$secondFontColor:		#525252;

$fixFont:					Verdana; 
$fixFontSize:			14px;
$fixFontHeight:		24px;

$font: 'PFDinTextPro'; 



// Variables => Colors
//----------------------------------------------------//
$default: #56606b;

$white: #fff;
$black: #000;

$light: #ededed;
$light2: #fefeff;

$blue1: #4a80c1;
$blue2: #00b4e5;
$blue3: #4b81c1;
$blue4: #4b81c1;
$blue5: #3b669a;
$blue6: #5f8ec7;

$red1: #e11248;
$red2: #f03163;

$green1: #78b833;
$green2: #6ca52e;
$green3: #70aa2f;
$green4: #85bf47;

$gray1: #7b8693;
$gray2: #aab0ba;
$gray3: #7a8595;
$gray4: #e3e3e3;
$gray5: #c7c9d1;
$gray6: #a8b0ba;
$gray7: #b5c5d9;
$gray8: #f3f3f3;
$gray9: #d8dde2;
$gray10: #e8e8e8;
$gray11: #6b737f;
$gray12: #687280;

$orange1: #ffa600;
$orange2: #ffaf1a;

$pink1: #8d3c99;

$speed: .3s;
$errorHeight: 34px;
// rub
.rub {
    width: 14px;
    height: 17px;
    fill: inherit;
    display: inline-block;
    &--blue {
        fill: $blue1;
    }
}