.drivermonitoring-icon {
    margin-left: 30px;
    margin-top: -30px;
}


.Select-menu-outer {
    z-index: 9999999 !important;
}

.long-button {
    height: 62px;
    margin-bottom: 30px;
    margin-top: -10px;
}

.right-aligned {
    margin-left: auto
}

.full-height-button {
    height: 100%;
    padding: 0;
}

.zero-padding {
    padding: 0 !important;
}

.driver-table {
    margin-bottom: 20px;
}

.monitoring-long-input {
    height: 61px;
}

.monitoring-placeholder {
    transform: translateY(23px);
}