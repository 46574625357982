.header {
    @extend %reset-block;
    border-bottom: 2px solid $white;
    position: relative; //position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    transform: translate3d(0, 0, 0);
    z-index: 100;
    flex: 0 0 auto;
    @include b(1600) {
        border: 0;
    }
    @include b(tablet) {
        position: relative;
    }
    &__flex {
        display: flex;
        height: 130px;
        justify-content: space-between;
        @include b(1600) {
            height: 95px;
        }
        @include b(tablet) {
            flex-wrap: wrap;
            height: auto;
        }
    }
    &-logo {
        background: $blue1;
        display: flex;
        align-items: center;
        padding: 0 40px;
        width: 227px;
        &__link {
            display: block;
            margin: auto; // margin-top: -18px;
            width: 100%;
            text-align: center;
        }
        &__img {
            display: inline-block;
            max-width: 100%;
            width: 147px;
            height: 74px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../../pic/logo.png');
            background-position: center;
        }

        @include b(1600) {
            width: 170px;
            padding: 0 30px;
        }
        @include b(tablet) {
            width: 240px;
            order: 0;
        }
        @include b(mobile) {
            width: 115px;
            padding: 0 20px;
            &__link {
                // margin-top: -9px;
            }
        }
        @include b(400) {
            width: 100px;
            padding: 0 10px;
        }
    }
    &-contacts {
        background: $green1;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;
        width: 250px;
        @extend %reset-block;
        @include b(1600) {
            width: 180px;
        }
        @include b(tablet) {
            width: calc(100% - 240px - 130px);
            order: 1;
        }
        @include b(mobile) {
            width: calc(100% - 115px - 65px);
        }
        @include b(400) {
            width: calc(100% - 100px - 55px);
        }
        &__slider {
            display: table;
        }
        &__item {
            text-align: center;
        }
        &__phone {
            color: $white;
            font: 800 21px $font;
            display: block;
            @include b(1600) {
                font-size: 15px;
            }
            @include b(tablet) {
                font-size: 36px;
                letter-spacing: -1.5px;
                line-height: 1;
            }
            @include b(mobile) {
                font-size: 18px;
                letter-spacing: -1px;
            }
            @include b(400) {
                font-size: 15px;
            }
        }
        &__text {
            color: $white;
            font: 400 14px $font;
            display: block;
            @include b(1600) {
                font-size: 12px;
            }
            @include b(tablet) {
                font-size: 24px;
                line-height: 1;
            }
            @include b(mobile) {
                font-size: 12px;
            }
        } // dots
        .owl-theme {
            .owl-nav {
                &.disabled {
                    &+.owl-dots {
                        margin: 15px 0 0;
                        @include b(mobile) {
                            margin-top: 7px;
                        }
                    }
                }
            }
            .owl-dots {
                .owl-dot {
                    margin-right: 7px;
                    &:last-child {
                        margin-right: 0;
                    }
                    span {
                        margin: 0;
                        border-radius: 50%;
                        background: rgba($white, .4);
                        transition: all $speed;
                        @include b(mobile) {
                            width: 8px;
                            height: 8px;
                        }
                    }
                    &.active {
                        span {
                            background: $white;
                        }
                    }
                    &:hover {
                        span {
                            background: rgba($white, .6);
                        }
                    }
                }
            }
        }
    }
    &-user {
        background: $blue1;
        padding: 0 40px;
        width: 355px;
        display: flex;
        align-items: center;
        @include b(1600) {
            width: 63px + 2px*2 + 30px*2;
            padding: 30px;
            justify-content: center;
            position: relative;
        }
        @include b(tablet) {
            width: 63px + 30px*2;
            order: 2;
        }
        @include b(mobile) {
            //width: 65px;
            width: 63px + 15px*2;
            padding: 15px;
        }
        @include b(400) {
            width: 63px + 10px*2;
            padding: 10px;
        }
        &__box {
            position: relative;
            width: 100%;
            @include b(1600) {
                position: static;
            }
        }
        &__link {
            display: flex;
            border: 2px solid $white;
            width: 100%;
            justify-content: space-between;
            padding: 0 15px;
            align-items: center;
            &:hover {
                opacity: .8;
            }
            &--open {
                transition: all $speed;
                .header-user__arrow-ico {
                    transform: rotate(180deg);
                }
            }
            @include b(1600) {
                padding: 0;
            }
            @include b(tablet) {
                border: 0;
            }
        }
        &__desc {
            text-align: right;
            overflow: hidden;
            &-name {
                color: $white;
                font: 500 18px $font;
                display: block;
                text-transform: uppercase;
            }
            &-text {
                color: $white;
                font: 200 13px $font;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            @include b(1600) {
                display: none;
            }
        }
        &__avatar {
            // margin: -2px 0;
            padding: 0 0px 0 10px;
            width: auto;
            &-user {
                position: relative;
                overflow: hidden;
                background: $blue1;
                fill: $white;
                width: 63px;
                height: 63px;
                display: flex;
                align-items: center;
                justify-content: center;
                &-ico {
                    display: block;
                    fill: inherit;
                    &-bg {
                        position: absolute;
                        display: block;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        fill: rgba($white, .1);
                    }
                }
            }
            &-img {
                display: block;
                max-width: 100%;
            }
            @include b(1600) {
                padding: 0;
                margin: 0;
            }
        }
        &__arrow {
            width: 50px;
            margin-right: -15px;
            text-align: center;
            height: 100%;
            transition: all $speed;
            transform: translate3d(0, 0, 0);
            &-ico {
                fill: $white;
                transition: all $speed;
            }
            @include b(1600) {
                display: none;
            }
        }
        &__drop {
            background: $blue1;
            border: 2px solid $white;
            border-top: 0;
            position: absolute;
            top: 100%;
            width: 100%;
            display: none;
            opacity: 0;
            z-index: 2;
            min-width: 200px;
            margin-top: -2px;
            &--open {
                transition: all $speed;
                opacity: 1;
                display: block;
            }
            &-link {
                text-transform: uppercase;
                color: $white;
                font: 400 16px/40px $font;
                display: block;
                height: 40px;
                padding: 0 15px;
                &:hover {
                    opacity: .8;
                }
            }
            @include b(1600) {
                right: 0;
                border-right: 0;
            }
        }
    }
    &-multi {
        background: $gray4;
        border-top: 2px solid $white;
        display: none;
        justify-content: space-between;
        &--visible {
            display: flex;
            flex-wrap: nowrap;
        }
        &__box {
            // overflow: auto;
            display: flex; //width: calc(100% - 88px);
            width: 100%;
            @include b(mobile) {
                // width: calc(100% - 55px);
            }
        }
        &__list {
            display: flex;
            white-space: nowrap;
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            flex: 1 1 auto;
            &-item {
                display: flex;
                padding: 11px 30px 9px;
                position: relative;
                border-right: 1px solid $gray5;
                color: $gray1;
                fill: $gray1;
                align-items: center;
                flex: 0 0 auto;
                &:before {
                    @include before-img(12px, 12px);
                    background: $gray4;
                    border: 1px solid $gray4;
                    left: -6px;
                    top: calc(50% - 6px);
                    z-index: 1;
                    border-bottom: 0;
                    border-left: 0;
                    transform: rotate(45deg);
                    opacity: 0;
                }
                &:after {
                    @include before-img(12px, 12px);
                    background: $gray4;
                    border: 1px solid $gray5;
                    right: -6px;
                    top: calc(50% - 6px);
                    z-index: 1;
                    border-bottom: 0;
                    border-left: 0;
                    transform: rotate(45deg);
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
                &.active {
                    background: $orange1;
                    border-color: $orange1;
                    color: $white;
                    fill: $white;
                    &:after {
                        background: $orange1;
                        border-color: $orange1;
                    }
                    &:before {
                        opacity: 1;
                    }
                    &-next {
                        &:before {
                            background: $orange1;
                        }
                    }
                }
                &--active {
                    flex: 0 0 227px;
                    padding: 0;
                    display: flex;
                    align-items: stretch;
                    &:after {
                        display: none;
                    }
                    .header-multi__list-val {
                        color: $white; // padding: 0 50px;
                        @include b(tablet) {
                            // padding: 0 30px;
                        }
                        @include b(mobile) {
                            // padding: 0 20px;
                        }
                    }
                    &+.header-multi__list-item {
                        &:before {
                            display: none;
                        }
                    }
                    @include b(1600) {
                        flex: 0 0 170px;
                    }
                    @include b(tablet) {
                        flex: 0 0 240px;
                    }
                    @include b(mobile) {
                        width: 50vw;
                    }
                    @include b(400) {
                        width: 50vw;
                    }
                }
            }
            &-val {
                color: inherit;
                font: 500 14px $font;
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase; // height: 100%;
                display: flex;
                align-items: center;
                &.btn {
                    display: flex;
                }
                &--flex {
                    flex: 1;
                }
            }
            &-close {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                fill: inherit;
                transition: all $speed;
                &-ico {
                    display: block;
                    width: 11px;
                    height: 11px;
                    margin-left: 10px;
                }
                &:hover {
                    fill: $blue1;
                    transition: all $speed;
                }
            }
        }
        &__add {
            height: auto;
        }
    }
}