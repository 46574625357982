.volma-time {
    display: flex;
    .volma-time__text {
        overflow: hidden;
        flex: 1 0 100px;
    }
    .volma-time__hours {
        flex: 0 0 80px;
        margin-left: -2px;
    }
    .volma-time__minutes {
        flex: 0 0 80px;
        margin-left: -2px;
    }
}