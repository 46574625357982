.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 102;
    background: rgba($blue1, .95);
    transition: all $speed;
    opacity: 0;
    visibility: hidden;
    &.active {
        opacity: 1;
        visibility: visible;
        transition: all $speed;
    }
    &--topmost {
        z-index: 151;
        background: rgba($blue1, .25);
    }
    &--pattern {
        background: url('../../../img/login-bg.png') no-repeat, url('../../../img/login-pattern.svg') no-repeat, $blue1;
        background-size: cover;
    }
}