/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$truck-desktop-name: 'truck-desktop';
$truck-desktop-x: 0px;
$truck-desktop-y: 98px;
$truck-desktop-offset-x: 0px;
$truck-desktop-offset-y: -98px;
$truck-desktop-width: 134px;
$truck-desktop-height: 34px;
$truck-desktop-total-width: 178px;
$truck-desktop-total-height: 158px;
$truck-desktop-image: '../img/sprite.png';
$truck-desktop: (0px, 98px, 0px, -98px, 134px, 34px, 178px, 158px, '../img/sprite.png', 'truck-desktop', );
$truck-mobile-name: 'truck-mobile';
$truck-mobile-x: 0px;
$truck-mobile-y: 137px;
$truck-mobile-offset-x: 0px;
$truck-mobile-offset-y: -137px;
$truck-mobile-width: 80px;
$truck-mobile-height: 21px;
$truck-mobile-total-width: 178px;
$truck-mobile-total-height: 158px;
$truck-mobile-image: '../img/sprite.png';
$truck-mobile: (0px, 137px, 0px, -137px, 80px, 21px, 178px, 158px, '../img/sprite.png', 'truck-mobile', );
$truck-tablet-name: 'truck-tablet';
$truck-tablet-x: 0px;
$truck-tablet-y: 0px;
$truck-tablet-offset-x: 0px;
$truck-tablet-offset-y: 0px;
$truck-tablet-width: 178px;
$truck-tablet-height: 46px;
$truck-tablet-total-width: 178px;
$truck-tablet-total-height: 158px;
$truck-tablet-image: '../img/sprite.png';
$truck-tablet: (0px, 0px, 0px, 0px, 178px, 46px, 178px, 158px, '../img/sprite.png', 'truck-tablet', );
$truck-name: 'truck';
$truck-x: 0px;
$truck-y: 51px;
$truck-offset-x: 0px;
$truck-offset-y: -51px;
$truck-width: 167px;
$truck-height: 42px;
$truck-total-width: 178px;
$truck-total-height: 158px;
$truck-image: '../img/sprite.png';
$truck: (0px, 51px, 0px, -51px, 167px, 42px, 178px, 158px, '../img/sprite.png', 'truck', );
$w-text-name: 'w-text';
$w-text-x: 139px;
$w-text-y: 98px;
$w-text-offset-x: -139px;
$w-text-offset-y: -98px;
$w-text-width: 21px;
$w-text-height: 20px;
$w-text-total-width: 178px;
$w-text-total-height: 158px;
$w-text-image: '../img/sprite.png';
$w-text: (139px, 98px, -139px, -98px, 21px, 20px, 178px, 158px, '../img/sprite.png', 'w-text', );
$spritesheet-width: 178px;
$spritesheet-height: 158px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($truck-desktop, $truck-mobile, $truck-tablet, $truck, $w-text, );
$spritesheet: (178px, 158px, '../img/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
