button {
    &:after {
        display: none;
    }
}

button,
.btn {
    text-align: center;
    vertical-align: top;
    font: 400 14px $font;
    padding: 7px 20px;
    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    position: relative;
    transition: all .3s ease-in-out;
    width: 100%;
    display: block;
    text-transform: uppercase;
    border: 0;
    outline: 0;
    &__text {
        display: inline-block;
        vertical-align: middle;
        margin: auto;
    }
    &__ico {
        display: inline-block;
        vertical-align: middle;
        fill: inherit;
        margin-left: 10px;
        @include b(mobile) {
            margin-left: 5px;
        }
    }
    &:hover,
    &.active {
        opacity: .8;
    }
    &:focus {}
    &.disabled,
    &[disabled="disabled"],
    &[disabled] {
        background: #ccc;
        color: #aaa;
        cursor: not-allowed;
    }
    &-red {
        background: $red1;
    }
    &-green {
        background: $green1;
    }
    &-red,
    &-green,
    &-table,
    &-modal,
    &-green {
        color: $white;
        font: 400 14px $font;
        fill: $white;
        padding: 11px 13px;
        &--large {
            font: 700 20px $font;
            padding: 27px 15px;
        }
        &--green {
            background: $green1;
        }
        &--red {
            background: $red1;
        }
        &--orange {
            background: $orange1;
        }
        &--bg {
            position: relative;
            overflow: hidden;
            font-weight: 500;
            padding: 9px 19px;
            border: 1px solid transparent;
            &:before {
                @include before-img(100%, 100%);
                top: 0;
                left: 0;
                background: rgba($black, .08);
                opacity: 0;
                transition: all $speed;
            }
            &:hover {
                border-color: $white;
                opacity: 1;
            }
            &:active {
                border-color: rgba($white, .8);
                opacity: 1;
                &:before {
                    opacity: 1;
                }
            }
        }
        &--full-height {
            height: 100%;
            display: flex;
            align-items: center;
        }
        @include b(1600) {
            font-size: 12px;
            padding: 9px 11px;
            &--bg {
                padding: 7px 17px;
            }
            &--large {
                font-size: 16px;
                padding: 16px 11px;
            }
        }
        @include b(tablet) {
            font-size: 24px;
            padding: 9px 16px;
            &--bg {
                font-size: 18px;
                padding: 16px;
            }
            &--large {
                font-size: 20px;
                padding: 20px 11px;
            }
        }
        @include b(mobile) {
            font-size: 12px;
            padding: 5px 9px;
            &--bg {
                padding: 6px 9px;
            }
            &--large {
                font-size: 16px;
                padding: 13px 10px;
            }
        }
    }
    &-red {
        color: $white;
        font: 400 14px $font;
        background: $red1;
        fill: $white;
        padding: 11px 13px;
        &--large {
            font: 700 20px $font;
            padding: 27px 15px;
        }
        @include b(1600) {
            font-size: 12px;
            padding: 9px 11px;
            &--large {
                font-size: 16px;
                padding: 16px 11px;
            }
        }
        @include b(tablet) {
            font-size: 24px;
            padding: 9px 16px;
            &--large {
                font-size: 20px;
                padding: 20px 11px;
            }
        }
        @include b(mobile) {
            font-size: 12px;
            padding: 5px 9px;
            &--large {
                font-size: 16px;
                padding: 13px 10px;
            }
        }
    }
    &-edit {
        background: $orange1;
        fill: $white;
        border: 1px solid transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &__ico {
            fill: inherit;
            display: block;
            width: 17px;
            height: 18px;
        }
        &--static {
            width: 32px;
            height: 32px;
            .btn-edit__ico {
                width: 19px;
                height: 19px;
            }
        }
        &:hover {
            border-color: $white;
            opacity: 1;
        }
        @include b(1600) {
            &__ico {
                width: 13px;
                height: 14px;
            }
        }
        @include b(tablet) {
            &__ico {
                width: 31px;
                height: 32px;
            }
        }
        @include b(mobile) {
            &__ico {
                width: 14px;
                height: 15px;
            }
        }
    }
    &-remove {
        background: $red1;
        fill: $white;
        border: 1px solid transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &__ico {
            fill: inherit;
            display: block;
            width: 17px;
            height: 18px;
        }
        &:hover {
            border-color: $white;
            opacity: 1;
        }
        @include b(1600) {
            &__ico {
                width: 13px;
                height: 14px;
            }
        }
        @include b(tablet) {
            &__ico {
                width: 29px;
                height: 32px;
            }
        }
        @include b(mobile) {
            &__ico {
                width: 13px;
                height: 15px;
            }
        }
    }
}

.btn-delete-pay {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    .btn__ico {
        margin: 0;
    }
}

.btn-add {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    .btn__ico {
        margin: 0;
    }
}

.set-wager {
    background: $green4;
    fill: $white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; // position: relative;
    overflow: hidden;
    &__ico {
        display: block;
        fill: inherit;
        margin: 0 auto 10px;
        position: relative;
        z-index: 1;
    }
    &__text {
        color: $white;
        font: 500 13px $font;
        display: block;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        z-index: 1;
    }
    &__bg {
        position: absolute;
        width: 95px;
        height: 95px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        background: $green1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-ico {
            display: block;
            width: 53px;
            height: 66px;
            fill: $green4;
        }
    }
    &:hover {
        opacity: .8;
    }
    &--orange {
        background: $orange2;
        .set-wager__bg {
            background: $orange1;
            &-ico {
                fill: $orange2;
            }
        }
    }
}