.textarea {
    height: 128px;
    padding: 19px 18px;
    @include b(1600) {
        height: 102px;
    }
    @include b(tablet) {
        height: 274px;
        padding: 30px 18px;
    }
    @include b(mobile) {
        height: 135px;
        padding: 14px 18px;
    }
}