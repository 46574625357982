.custom-select {
    position: relative;
    &--active {
        .custom-select__select {
            background: $blue1;
            color: $white;
        }
        .custom-select__arrow {
            fill: $white;
            transform: rotate(180deg);
        }
    }
    &__inner {}
    &__select {
        display: flex;
        align-items: center;
    }
    &__drop {
        background: $blue1;
        // position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0 18px;
        &-list {}
        &-item {
            border-top: 2px solid $white;
        }
        &-link {
            color: $white;
            font: 300 15px $font;
            font-style: italic;
            text-transform: uppercase;
            display: block;
            padding: 23px 18px;
            margin: 0 -18px;
            &:hover {
                background: $blue6;
            }
        }
    }
}