//-- SVG ICO START --//
.plus-ico {
    width: 19px;
    height: 19px;
    @include b(1600) {
        width: 15px;
        height: 15px;
    }
    @include b(tablet) {
        width: 29px;
        height: 30px;
    }
    @include b(mobile) {
        width: 15px;
        height: 16px;
    }
}

.close-ico {
    width: 17px;
    height: 17px;
    @include b(1600) {
        width: 13px;
        height: 13px;
    }
    @include b(tablet) {
        width: 28px;
        height: 29px;
    }
    @include b(mobile) {
        width: 15px;
        height: 15px;
    }
}

.bg-phone-ico {
    width: 104px;
    height: 104px;
}

.arrow-down-ico {
    width: 18px;
    height: 10px;
}

.info-block-ico {
    width: 28px;
    height: 28px;
    @include b(1600) {
        width: 22px;
        height: 24px;
    }
    @include b(tablet) {
        width: 51px;
        height: 52px;
    }
    @include b(mobile) {
        width: 28px;
        height: 28px;
    }
}

.info-bg-ico {
    width: 100px;
    height: 92px;
    @include b(1600) {
        width: 80px;
        height: 92px;
    }
    @include b(tablet) {
        width: 120px;
        height: 120px;
    }
    @include b(mobile) {
        width: 80px;
        height: 80px;
    }
}

.info-ico {
    width: 29px;
    height: 29px;
}

.date-ico {
    width: 23px;
    height: 26px;
    @include b(mobile) {
        width: 16px;
        height: 18px;
    }
}

.point-ico {
    width: 28px;
    height: 28px;
    @include b(mobile) {
        width: 22px;
        height: 22px;
    }
}

.bg-plus-ico {
    width: 122px;
    height: 122px;
}

.table {
    &-date-ico {
        width: 12px;
        height: 13px;
    }
    &-clock-ico {
        width: 13px;
        height: 13px;
    }
    &-filter-ico {
        width: 12px;
        height: 10px;
    }
    &-search-ico {
        width: 13px;
        height: 12px;
    }
}

.header {
    &-search-ico {
        width: 18px;
        height: 18px;
        @include b(1600) {
            width: 16px;
            height: 16px;
        }
        @include b(tablet) {
            width: 22px;
            height: 22px;
        }
        @include b(mobile) {
            width: 14px;
            height: 14px;
        }
    }
}

.table-cont {
    &-date-ico {
        width: 14px;
        height: 16px;
    }
    &-clock-ico {
        width: 14px;
        height: 16px;
    }
    &-check-ico {
        width: 23px;
        height: 17px;
    }
    &-user-ico {
        width: 19px;
        height: 20px;
    }
    &-upload-ico {
        width: 20px;
        height: 19px;
    }
    &-place-ico {
        width: 16px;
        height: 20px;
    }
}

.way-place-ico {
    width: 15px;
    height: 18px;
    @include b(mobile) {
        width: 8px;
        height: 10px;
    }
}

.way-clock-ico {
    width: 18px;
    height: 18px;
    @include b(tablet) {
        width: 32px;
        height: 33px;
    }
    @include b(mobile) {
        width: 14px;
        height: 14px;
    }
}

.way-wheel1-ico {
    width: 18px;
    height: 18px;
    @include b(1600) {
        width: 16px;
        height: 16px;
    }
    @include b(tablet) {
        width: 30px;
        height: 30px;
    }
    @include b(mobile) {
        width: 14px;
        height: 15px;
    }
}

.way-size-vertical-ico {
    width: 18px;
    height: 18px;
    @include b(1600) {
        width: 16px;
        height: 16px;
    }
    @include b(tablet) {
        width: 30px;
        height: 30px;
    }
    @include b(mobile) {
        width: 14px;
        height: 15px;
    }
}

.way-size-horizontal-ico {
    width: 18px;
    height: 18px;
    @include b(1600) {
        width: 16px;
        height: 16px;
    }
    @include b(tablet) {
        width: 30px;
        height: 30px;
    }
    @include b(mobile) {
        width: 14px;
        height: 15px;
    }
}

.way-flag-ico {
    width: 17px;
    height: 16px;
    @include b(mobile) {
        width: 9px;
        height: 9px;
    }
}

.table-drop-filter-ico {
    width: 28px;
    height: 24px;
    @include b(1600) {
        width: 22px;
        height: 18px;
    }
    @include b(tablet) {
        width: 28px;
        height: 24px;
    }
    @include b(mobile) {
        width: 22px;
        height: 18px;
    }
}

.table-close-ico {
    width: 16px;
    height: 16px;
    @include b(1600) {
        width: 14px;
        height: 14px;
    }
    @include b(tablet) {
        width: 16px;
        height: 16px;
    }
    @include b(mobile) {
        width: 14px;
        height: 14px;
    }
}

.chevron-down-ico {
    width: 18px;
    height: 12px;
}

.table-drop-clock-ico {
    width: 28px;
    height: 28px;
}

.table-drop-date-ico {
    width: 17px;
    height: 20px;
}

.input-rub-ico {
    width: 22px;
    height: 27px;
}

.truck-cab-ico {
    width: 30px;
    height: 29px;
}

.truck-trailer-ico {
    width: 30px;
    height: 37px;
}

.w-text-ico {
    width: 21px;
    height: 20px;
}

.x-text-ico {
    width: 17px;
    height: 20px;
}

.file-upload-ico {
    width: 24px;
    height: 23px;
}

.message-ico {
    width: 16px;
    height: 16px;
}

.correspondence {
    &-user-ico {
        width: 29px;
        height: 30px;
        &-bg {
            width: 63px;
            height: 63px;
        }
    }
}

.avatar {
    &-user-ico {
        width: 29px;
        height: 30px;
        &-bg {
            width: 63px;
            height: 63px;
        }
    }
}

.title-message-check-ico {
    width: 20px;
    height: 19px;
}

.title-airplane-ico {
    width: 20px;
    height: 17px;
}

.inform-message-check-ico {
    width: 30px;
    height: 29px;
}

.inform-message-uncheck-ico {
    width: 30px;
    height: 30px;
}

.inform-airplane-ico {
    width: 30px;
    height: 25px;
}

.btn-airplane-ico {
    width: 30px;
    height: 25px;
}

.pay-delete-ico {
    width: 20px;
    height: 23px;
}

.table-cont-building-ico {
    width: 20px;
    height: 20px;
}

.table-cont-wheel1-ico {
    width: 20px;
    height: 20px;
}

.table-cont-size-vertical-ico {
    width: 20px;
    height: 20px;
}

.table-cont-size-horizontal-ico {
    width: 20px;
    height: 20px;
}

.table-cont-plus-ico {
    width: 19px;
    height: 18px;
}

.table-cont-eye-ico {
    width: 19px;
    height: 12px;
}

.table-cont-relaod-ico {
    width: 20px;
    height: 21px;
}

.table-cont-close-ico {
    width: 18px;
    height: 18px;
}

.table-cont-tie-ico {
    width: 11px;
    height: 20px;
}

.table-cont-printer-ico {
    width: 20px;
    height: 20px;
}

.light-upload-ico {
    width: 30px;
    height: 29px;
}

.btn-plus-ico {
    width: 13px;
    height: 13px;
}

.rub-circle-ico {
    width: 30px;
    height: 30px;
}

.modal-key-ico {
    width: 23px;
    height: 24px;
}

.clock-ico {
    width: 27px;
    height: 29px;
}

.clock-bg-ico {
    width: 100px;
    height: 92px;
}

.accept-ico {
    width: 23px;
    height: 17px;
}

.status-clock-ico {
    width: 14px;
    height: 14px;
}

.search-ico {
    width: 28px;
    height: 24px;
    @include b(1600) {
        width: 22px;
        height: 18px;
    }
    @include b(tablet) {
        width: 28px;
        height: 24px;
    }
    @include b(mobile) {
        width: 22px;
        height: 18px;
    }
}

.search-relaod-ico {
    width: 16px;
    height: 16px;
    @include b(1600) {
        width: 14px;
        height: 14px;
    }
    @include b(tablet) {
        width: 16px;
        height: 16px;
    }
    @include b(mobile) {
        width: 14px;
        height: 14px;
    }
}

//-- SVG ICO FINISH --//
//-- SPRITE ICO START --//
.truck-ico {
    @include sprite($truck);
    @include b(1600) {
        @include sprite($truck-desktop);
    }
    @include b(tablet) {
        @include sprite($truck-tablet);
    }
    @include b(mobile) {
        @include sprite($truck-mobile);
    }
}

//-- SPRITE ICO FINISH --//