.status {
    @extend %reset-block;
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        display: inline-block;
        width: calc(100% / 5);
        position: relative;
        &:last-child {
            .status__link {
                border-right-width: 0;
            }
        }
        @include b(1600) {
            width: calc(100% / 3);
            &:nth-child(3n) {
                .status__link {
                    border-right-width: 0;
                }
            }
        }
        @include b(tablet) {
            width: calc(100% / 2);
            &:nth-child(3n) {
                .status__link {
                    border-right-width: 1px;
                }
            }
            &:nth-child(2n) {
                .status__link {
                    border-right-width: 0;
                }
            }
        }
        @include b(mobile) {
            // width: auto;
            // &:nth-child(1n) {
            //     .status__link {
            //         border-right-width: 1px;
            //     }
            // }
        }
    }
    &__link {
        background: $gray4;
        color: $gray3;
        font: 200 15px $font;
        text-transform: uppercase;
        position: relative;
        display: block;
        padding: 12px 40px;
        border-right: 1px solid $gray5;
        fill: $gray3;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: default;
        border-bottom: 1px solid $gray5;
        &:before {
            @include before-img(20px, 20px);
            border: 1px solid $gray3;
            border-radius: 50%;
            left: 10px;
            top: calc(50% - 10px);
        }
        &:after {
            @include before-img(0px, 0px);
            border: 5px solid $gray3;
            border-radius: 50%;
            left: 15px;
            top: calc(50% - 5px);
            opacity: 0;
            visibility: hidden;
        }
        &--blue {
            background: $blue1;
            border-color: $white;
            color: $white;
            fill: $white;
            &:before,
            &:after {
                border-color: $white;
            }
        }
        &--green {
            cursor: pointer;
            background: $green1;
            border-color: $white;
            color: $white;
            fill: $white;
            &:before,
            &:after {
                border-color: $white;
            }
        }
        &.checked {
            border-right: 1px solid $white;
            &:after {
                opacity: 1;
                visibility: visible;
            }
            .status__arrow {
                display: none;
            }
        }
        &.changed {
            border-right: 1px solid $white;
            &:after {
                opacity: 1;
                visibility: visible;
            }
            .status__arrow {
                fill: $white;
            }
        }
        &-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-icon {
            display: inline-block;
            position: relative;
            top: 2px;
            margin: 0 2px 0 0;
            fill: inherit;
        }
        @include b(tablet) {
            font-size: 19px;
        }
        @include b(mobile) {
            font-size: 14px;
            // width: 35px;
            // height: 35px;
            // padding: 0;
            // &-text {
            //     display: none;
            // }
            // &:before {
            //     left: calc(50% - 10px);
            // }
            // &:after {
            //     left: calc(50% - 5px);
            // }
        }
    }
    &__arrow {
        display: block;
        width: 14px;
        height: 8px;
        fill: $gray3;
        position: absolute;
        right: 13px;
        top: calc(50% - 4px);
        @include b(mobile) {
            display: none;
        }
    }
    &-drop {
        position: absolute;
        top: 0;
        background: $green1;
        width: 100%;
        box-shadow: 0 0 21px rgba(4, 0, 0, 0.35);
        display: none;
        z-index: 10;
        min-width: 245px;
        &.active {
            display: block;
        }
        &__item {
            display: block;
            padding: 12px 15px;
            position: relative;
            &:before {
                @include before-img(calc(100% - 20px), 1px);
                bottom: 0;
                background: rgba($white, .4);
                left: 10px;
            }
            &:last-child {
                &:before {
                    display: none;
                }
            }
            &:hover {
                background: $green4;
            }
        }
    }
}