@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .default-select--ico {
        .select {
            padding-left: 56px;
        }
    }
    .header-multi {
        &__list {
            // display: table;
            width: 100%;
            &-item {
                display: inline-block;
                vertical-align: middle;
                &--active {
                    .header-multi__list-val {
                        width: 100%;
                        display: flex;
                    }
                }
            }
            &-val {
                width: calc(100% - 21px);
                display: inline-block;
            }
        }
    }
    @include b(580) {
        .table__body {
            width: 0;
        }
    }
    @include b(mobile) {
        .header-logo__img {
            width: 100%;
        }
        .header-user__avatar-img {
            width: 100%;
        }
    }
}