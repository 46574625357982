.modal {
    z-index: 103;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    @extend %reset-block;
    display: none;
    align-items: center;
    &.active {
        display: flex;
    }
    &--topmost {
        z-index: 152;
    }
    &__logo {
        background: $white;
        position: absolute;
        width: 270px;
        top: 0;
        left: 0;
        padding: 23px 43px 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-img {
            display: block;
            max-width: 100%;
            width:184px;
            height: 81px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../../pic/logo-blue.png');
            background-position: center;
        }
        &:hover {
            opacity: .8;
        }
        @include b(1600) {
            width: 170px;
            padding: 17px 34px 34px;
        }
        @include b(tablet) {
            width: 220px;
            order: 0;
        }
        @include b(mobile) {
            width: 115px;
            padding: 13px 23px 23px;
        }
        @include b(400) {
            width: 100px;
            padding: 7px 15px 15px;
        }
    }
    &__close {
        position: absolute;
        top: 62px;
        right: 59px;
        fill: $white;
        &-ico {
            display: block;
            fill: inherit;
            width: 28px;
            height: 28px;
        }
        &:hover {
            opacity: .8;
        }
        @include b(mobile) {
            right: 10px;
            top: 29px;
            &-ico {
                width: 22px;
                height: 22px;
            }
        }
    }
    &-wrap {
        margin: auto;
        width: 100%;
        max-height: calc(100% - 48px);
        display: flex;
        &--cont {
            max-width: 1080px;
        }
    }
    &-cont {
        background: rgba($white, .09);
        padding: 48px 60px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height:100%;
        overflow: hidden;
        &__title {
            color: $white;
            font: 700 45px $font;
            display: block;
            text-align: center;
            text-transform: uppercase;
            padding: 0 50px 36px;
        }
        @include b(mobile) {
            padding: 24px 0px 0;
            &__title {
                font-size: 24px;
                padding: 0 40px 18px;
            }
        }
    }
    &-change {
        text-align: center;
        padding-top: 55px;
        &__link {
            display: inline-block;
            color: $white;
            font: 400 18px $font;
            fill: $white;
            border-color: $white;
            &-ico {
                display: inline-block;
                vertical-align: middle;
                fill: inherit;
                margin-right: 8px;
            }
            &-text {
                display: inline-block;
                vertical-align: middle;
                border-bottom: 1px solid $white;
                border-color: inherit;
            }
            &:hover {
                border-color: transparent;
            }
        }
        @include b(mobile) {
            padding-top: 25px;
        }
    }
    // bet
    &-bet {
        background: $white;
        padding: 48px 59px 44px;
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
        @include b(mobile) {
            padding: 28px 20px 24px;
        }
        &__form {
            &-row {
                margin: 0 -30px;
                padding-bottom: 33px;
                &:last-child {
                    padding-bottom: 0;
                }
                @include b(mobile) {
                    padding-bottom: 15px;
                }
            }
            &-cell {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 30px;
                &--full {
                    width: 100%;
                }
                @include b(mobile) {
                    width: 100%;
                    padding-bottom: 10px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
    &-confirm {
        background: $white;
        padding: 48px 59px 44px;
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
        @include b(mobile) {
            padding: 28px 20px 24px;
        }
        &__form {
            &-row {
                margin: 0 -30px;
                padding-bottom: 33px;
                &--center {
                    text-align: center;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                @include b(mobile) {
                    padding-bottom: 15px;
                }
            }
            &-cell {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 30px;
                &--full {
                    width: 100%;
                }
                @include b(mobile) {
                    width: 100%;
                    padding-bottom: 10px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
            &-text {
                position: relative;
                color: $gray3;
                font: 400 24px $font;
                width: 100%;
                padding: 0 60px;
                text-align: center;
            }
            @include b(1600) {
                &-text {
                    font-size: 22px;
                }
            }
            @include b(tablet) {
                &-text {
                    font-size: 20px;
                }
            }
            @include b(mobile) {
                &-text {
                    font-size: 18px;
                }
            }
        }
    }
    // default
    &-default {
        display: flex;
        flex-direction: column;
        background: $white;
        padding: 53px 59px 44px;
        overflow-y: auto;
        @include b(mobile) {
            padding: 28px 20px 24px;
        }
        &__row {
            margin: 0 -30px;
            padding-bottom: 33px;
            display: flex;
            &:last-child {
                padding-bottom: 0;
            }
            @include b(mobile) {
                padding-bottom: 15px;
            }
            &--no-shrink {
                flex-shrink: 0;
            }
            &--column {
                flex-direction: column;
            }
        }
        &__row-inner {
            // overflow: auto;
            width: 100%;
        }
        &__cell {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding: 0 30px;
            &--full {
                width: 100%;
            }
            @include b(mobile) {
                width: 100%;
                padding-bottom: 10px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    // exel
    &-exel {
        background: $white;
        padding: 53px 59px 44px;
        @include b(mobile) {
            padding: 28px 20px 24px;
        }
        &__row {
            padding-bottom: 19px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    // login
    &-login {
        background: $white;
        padding: 59px 59px 52px;
        @include b(mobile) {
            padding: 28px 20px 24px;
        }
        &__row {
            padding-bottom: 28px;
            &:last-child {
                padding-bottom: 0;
            }
            @include b(mobile) {
                padding-bottom: 15px;
            }
        }
    }
    // modificators
    &--login {
        .modal-cont {
            background: none;
        }
    }
}