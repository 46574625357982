.default-date {
    position: relative;
    display: block;
    &__input {
        position: relative;
        z-index: 1;
    }
    &__ico-wrapper {
        height: calc(100% - #{$errorHeight});
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        width: 60px;
        z-index: 1; 

        &--dark {
            height: calc(100%);
            border-left: 2px solid #fff; 
        }   

        &--clear {
            right: 60px;
            cursor: default;
            
            span.Select-clear-zone {
                position: absolute;
                top: calc(50% - 10px);
                right: 0;
            }
        } &--dark {            
            span.Select-clear-zone {
                color: #fff;
                top: calc(50% - 12px);
                right: calc(50% - 10px);                
                
                .Select-clear {
                    font-size: 24px;
                }

                &:hover {
                    color: #D0021B;
                }
            }  
        }
    }
    &__ico {
        position: absolute;
        right: calc(50% - 11px);
        top: calc(50% - 13px);
        fill: $blue1;

        @include b(mobile) {
            top: calc(50% - 9px);
            right: calc(50% - 8px);
        }

        &--dark {
            fill: #fff;       
        }
    }
}