@mixin header-button {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $gray6;
    fill: $gray6;
    display: block;
    margin-bottom: 6px;

    &-ico {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        fill: inherit;
        display: block;
    }

    &:hover {
        background: $white;
        opacity: 1;
        border-color: $white;
    }

    &--active {
        background: $blue1;
        fill: $white;
        border-color: $white;
        &:hover {
            fill: $blue1;
            border-color: $blue1;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }    
}